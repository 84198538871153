import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import {
  IReferralPayout,
  IReferralReward,
  IReferralStats,
  IReferralUser,
  TReferralPayoutsListQuery,
  TReferralRewardsListQuery,
  TReferralUsersListQuery,
} from 'types';
import { IUserReferalsState } from 'store/slices/userReferralSlice';

export const fetchReferralStats = createAsyncThunk<
  IReferralStats,
  void,
  { state: { auth: IAuthState } }
>('userReferral/referralStats', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');

  const referralStatsResponse = await UserRepository.getReferralStats(token);
  if (referralStatsResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralStatsResponse.error);

  const { data } = referralStatsResponse;
  const { balance, level, link, hashRate } = data[0];
  const referralStats: IReferralStats = {
    referralCurrentBalance: Number.parseFloat(balance).toFixed(2),
    referralCurrentRate: Number.parseFloat(hashRate).toFixed(),
    referralLevel: level,
    referralMaxRate: '0',
    referralPercent: 0.5,
    referralLinkAddress: link,
  };

  return referralStats;
});

export const fetchReferralUsersList = createAsyncThunk<
  { users: IReferralUser[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralUserList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralUsersList: { currentPage, itemsPerPage, search, sortBy, sortDir, fromDate, toDate },
    },
  } = getState();
  if (!token) throw Error('no token');

  const params: TReferralUsersListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  if (fromDate) params.from = fromDate;
  if (toDate) params.to = toDate;
  const referralUsersListResponse = await UserRepository.getReferralUsersList(token, params);
  if (referralUsersListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralUsersListResponse.error);

  const { result, count, size } = referralUsersListResponse.data;

  return { users: result, count, size };
});

export const fetchReferralRewardsList = createAsyncThunk<
  { rewards: IReferralReward[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralRewardsList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralRewardsList: { currentPage, itemsPerPage, search, sortBy, sortDir, fromDate, toDate },
    },
  } = getState();
  if (!token) throw Error('no token');
  const params: TReferralRewardsListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  if (fromDate) params.from = fromDate;
  if (toDate) params.to = toDate;
  const referralRewardsListResponse = await UserRepository.getReferralRewards(token, params);
  if (referralRewardsListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralRewardsListResponse.error);

  const { result, count, size } = referralRewardsListResponse.data;

  return { rewards: result, count, size };
});

export const fetchReferralsPayoutsList = createAsyncThunk<
  { payouts: IReferralPayout[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userReferrals: IUserReferalsState } }
>('userReferral/referralPayoutsList', async (_, { getState }) => {
  const {
    auth: { token },
    userReferrals: {
      referralPayoutsList: { currentPage, itemsPerPage, search, sortBy, sortDir, fromDate, toDate },
    },
  } = getState();
  if (!token) throw Error('no token');
  const params: TReferralPayoutsListQuery = {
    page: String(currentPage - 1),
    size: String(itemsPerPage),
  };
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  if (fromDate) params.from = fromDate;
  if (toDate) params.to = toDate;
  const referralPayoutsListResponse = await UserRepository.getReferralPayouts(token, params);
  if (referralPayoutsListResponse.status === EApiResponseStatus.FAILURE)
    throw Error(referralPayoutsListResponse.error);

  const { result, count, size } = referralPayoutsListResponse.data;

  return { payouts: result, count, size };
});
