import { apiGateway } from './apiUsersRoutes';

export const apiAdminRoutes = {
  apiAdminPaymentsCalculate() {
    return `${apiGateway}/admin/accruals/calculate`;
  },
  apiFetchServerDateTime() {
    return `${apiGateway}/server/time`;
  },
  apiAdminCosts() {
    return `${apiGateway}/admin/costs`;
  },
  apiAdminYesterdayCost() {
    return `${apiGateway}/admin/cost`;
  },
  apiAdminNewbieCost() {
    return `${apiGateway}/admin/newbie/cost`;
  },
  apiAdminNewbieAdjust() {
    return `${apiGateway}/admin/newbie/adjust`;
  },
};
