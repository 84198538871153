import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminRepository } from 'repositories/AdminRepository';
import { IAuthState } from 'store/slices/authSlice';
import { EApiResponseStatus } from 'types/api';
import { ICosts } from 'types/costs';

export const fetchCosts = createAsyncThunk<ICosts, void, { state: { auth: IAuthState } }>(
  'adminCosts/fetchCosts',
  async (_, { getState }) => {
    const {
      auth: { token },
    } = getState();
    if (!token) throw Error('no token');

    const paymentsCalculateResponse = await AdminRepository.getCosts(token);

    if (paymentsCalculateResponse.status === EApiResponseStatus.FAILURE) {
      throw Error(paymentsCalculateResponse.error);
    }

    return paymentsCalculateResponse.data;
  },
);
