import { TSortChargeByHeaderTitle } from 'types';
import { StyledChargeListHeader } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { useTranslation } from 'react-i18next';
interface IChargeListHeaderProps {
  children?: React.ReactNode;
  onSort: (sortBy: TSortChargeByHeaderTitle) => void;
  sortBy: string | null;
  sortDir: 'ASC' | 'DESC' | 'NONE';
}
export const ChargeListHeader = ({ onSort, sortBy, sortDir }: IChargeListHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledChargeListHeader>
      <div className="charges-list-header-element date-time">
        <div>{t('profitPage.chargesList.header.dateTime')}</div>
        <button
          className="sort-by-button"
          onClick={() => onSort('date')}
          style={{
            opacity: sortBy === 'date' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${sortBy === 'date' && sortDir !== 'NONE' ? '1.2' : '1'}) rotateZ(${
              sortBy === 'date' && sortDir === 'ASC' ? '180deg' : '0deg'
            })`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element hashrate">
        <div>{t('profitPage.chargesList.header.hashRate')}</div>
        <button
          className="sort-by-button"
          onClick={() => onSort('hashRate')}
          style={{
            opacity: sortBy === 'hashRate' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'hashRate' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'hashRate' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element general-accruals">
        <div>{t('profitPage.chargesList.header.amount')}</div>
        <button
          className="sort-by-button"
          onClick={() => onSort('amount')}
          style={{
            opacity: sortBy === 'amount' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${sortBy === 'amount' && sortDir !== 'NONE' ? '1.2' : '1'}) rotateZ(${
              sortBy === 'amount' && sortDir === 'ASC' ? '180deg' : '0deg'
            })`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element">
        <div>{t('profitPage.chargesList.header.rate')}</div>
        <button
          className="sort-by-button"
          onClick={() => onSort('rate')}
          style={{
            opacity: sortBy === 'rate' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${sortBy === 'rate' && sortDir !== 'NONE' ? '1.2' : '1'}) rotateZ(${
              sortBy === 'rate' && sortDir === 'ASC' ? '180deg' : '0deg'
            })`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
    </StyledChargeListHeader>
  );
};
