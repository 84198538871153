import { AdminElementsWrapper, Button } from 'components';
import { StyledAdminCalculatePayments } from './styles';
import { useTranslation } from 'react-i18next';
import { getFormatedServerDateTime } from 'utils';
import { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/store';
import { fetchPaymentsCalculate, fetchServerDateTime } from 'store/thunks/adminPaymentThunks';
import { DEFAULT_TIMEOUT_MS } from 'constants/appConstants';
interface IAdminCalculatePaymentsProps {
  children?: React.ReactNode;
}
export const AdminCalculatePaymentsComponent = ({ children }: IAdminCalculatePaymentsProps) => {
  const { t } = useTranslation();
  const { serverDateTime } = useAppSelector((state) => state.adminPayments);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeout = getServerDateTime();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const getServerDateTime = () => {
    dispatch(fetchServerDateTime());

    return setTimeout(() => {
      getServerDateTime();
    }, DEFAULT_TIMEOUT_MS);
  };

  const handleCalculateButton = async () => {
    dispatch(fetchPaymentsCalculate());
  };

  return (
    <StyledAdminCalculatePayments>
      <AdminElementsWrapper>
        <div className="content">
          <div>
            <h3 className="heading">{t('adminPage.calculatePayment.heading')}</h3>
            <p className="server-time">
              {t('adminPage.calculatePayment.serverTime') + ': '}
              {serverDateTime && getFormatedServerDateTime(serverDateTime).toLocaleString()}
            </p>
          </div>
          <Button
            title={t('adminPage.calculatePayment.startCalculation')}
            onClick={handleCalculateButton}
          />
        </div>
      </AdminElementsWrapper>
    </StyledAdminCalculatePayments>
  );
};

export const AdminCalculatePayments = memo(AdminCalculatePaymentsComponent);
