import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledQRDialogContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 1rem;
  padding-top: 8rem;

  @media screen and (min-width: 768px) {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledQRDialogOverlay = styled.div`
  position: absolute;
  opacity: 0;
  background: ${darkTheme.accent2};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: opacity 0.5s;
  &.show {
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.39' : '0.75')};
  }
`;
export const StyledQRCodeDialog = styled.dialog`
  position: relative;
  min-height: 30rem;

  border-radius: 15px;
  box-shadow: 0px 31px 20px 0px rgba(0, 0, 0, 0.25);

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};

  background-color: ${(props) => props.theme.bg3};

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  padding: 2rem;
  padding-bottom: 4rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow: auto;
  max-height: calc(100dvh - 8rem);
  max-width: 42.5rem;
  margin: auto;

  @media screen and (min-width: 768px) {
    max-width: 46.5rem;
    padding: 1.2rem 1.2rem 3rem 2rem;
    max-height: 100dvh;
    padding-bottom: 8rem;
  }

  .qr-dialog-heading {
    text-align: center;
    margin-bottom: 2rem;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
      margin-bottom: 5rem;
    }
  }

  .qr-dialog-code-container {
    margin: auto;
    width: 25rem;
    height: 25rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;

  display: block;
  width: 3rem;
  height: 3rem;

  @media screen and (min-width: 768px) {
    right: 1.2rem;
    top: 1.2rem;
  }

  > * {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.button2)};
  }

  &:hover,
  &:active {
    > * {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
    }
  }
`;
