import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  fetchReferralRewardsList,
  fetchReferralsPayoutsList,
  fetchReferralStats,
  fetchReferralUsersList,
} from 'store/thunks/userReferralThunks';
import { IReferralPayout, IReferralReward, IReferralUser } from 'types';
import { ESliceDataFetchingStatus } from 'types/common';
import { IReferralStats } from 'types';
import { PayloadAction } from '@reduxjs/toolkit';

export interface IUserReferalsState {
  referralStats: IReferralStats;
  referralStatsStatus: ESliceDataFetchingStatus;
  referralUsersListStatus: ESliceDataFetchingStatus;
  referralPayoutsListStatus: ESliceDataFetchingStatus;
  referralRewardsListStatus: ESliceDataFetchingStatus;
  referralUsersList: {
    users: IReferralUser[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
    search: string | null;
    sortBy: string | null;
    sortDir: string | null;
    fromDate: string | null;
    toDate: string | null;
  };
  referralRewardsList: {
    rewards: IReferralReward[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
    search: string | null;
    sortBy: string | null;
    sortDir: string | null;
    fromDate: string | null;
    toDate: string | null;
  };
  referralPayoutsList: {
    payouts: IReferralPayout[];
    pagesQuantity: number;
    itemsPerPage: number;
    currentPage: number;
    search: string | null;
    sortBy: string | null;
    sortDir: string | null;
    fromDate: string | null;
    toDate: string | null;
  };
}

const initialState: IUserReferalsState = {
  referralStats: {
    referralCurrentRate: null,
    referralLevel: null,
    referralMaxRate: null,
    referralPercent: null,
    referralCurrentBalance: null,
    referralLinkAddress: null,
  },
  referralUsersList: {
    users: [],
    pagesQuantity: 1,
    itemsPerPage: 10,
    currentPage: 1,
    search: null,
    sortBy: null,
    sortDir: null,
    fromDate: null,
    toDate: null,
  },
  referralPayoutsList: {
    payouts: [],
    pagesQuantity: 1,
    itemsPerPage: 10,
    currentPage: 1,
    search: null,
    sortBy: null,
    sortDir: null,
    fromDate: null,
    toDate: null,
  },
  referralRewardsList: {
    rewards: [],
    pagesQuantity: 1,
    itemsPerPage: 10,
    currentPage: 1,
    search: null,
    sortBy: null,
    sortDir: null,
    fromDate: null,
    toDate: null,
  },
  referralUsersListStatus: ESliceDataFetchingStatus.initial,
  referralStatsStatus: ESliceDataFetchingStatus.initial,
  referralPayoutsListStatus: ESliceDataFetchingStatus.initial,
  referralRewardsListStatus: ESliceDataFetchingStatus.initial,
};

export const userReferralSlice = createSlice({
  name: 'referralData',
  initialState,
  reducers: {
    referralRewardsListSetPage: (state, action: PayloadAction<number>) => {
      state.referralRewardsList.currentPage = action.payload;
    },
    setItemsPerReferralRewardsListPage: (state, action: PayloadAction<number>) => {
      state.referralRewardsList.itemsPerPage = action.payload;
      state.referralRewardsList.currentPage = initialState.referralRewardsList.currentPage;
    },
    setReferralRewardsSearch: (
      state,
      action: PayloadAction<IUserReferalsState['referralRewardsList']['search']>,
    ) => {
      state.referralRewardsList.search = action.payload;
    },
    setReferralRewardsSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserReferalsState['referralRewardsList']['sortBy'];
        sortDir: IUserReferalsState['referralRewardsList']['sortDir'];
      }>,
    ) => {
      state.referralRewardsList.sortBy = action.payload.sortBy;
      state.referralRewardsList.sortDir = action.payload.sortDir;
    },
    setReferralRewardsDateFilter: (
      state,
      action: PayloadAction<{
        fromDate: IUserReferalsState['referralRewardsList']['fromDate'];
        toDate: IUserReferalsState['referralRewardsList']['toDate'];
      }>,
    ) => {
      state.referralRewardsList.fromDate = action.payload.fromDate;
      state.referralRewardsList.toDate = action.payload.toDate;
    },
    referralPayoutsListSetPage: (state, action: PayloadAction<number>) => {
      state.referralPayoutsList.currentPage = action.payload;
    },
    setItemsPerReferralPayoutsListPage: (state, action: PayloadAction<number>) => {
      state.referralPayoutsList.itemsPerPage = action.payload;
      state.referralPayoutsList.currentPage = initialState.referralPayoutsList.currentPage;
    },
    setReferralPayoutsSearch: (
      state,
      action: PayloadAction<IUserReferalsState['referralPayoutsList']['search']>,
    ) => {
      state.referralPayoutsList.search = action.payload;
    },
    setReferralPayoutsSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserReferalsState['referralPayoutsList']['sortBy'];
        sortDir: IUserReferalsState['referralPayoutsList']['sortDir'];
      }>,
    ) => {
      state.referralPayoutsList.sortBy = action.payload.sortBy;
      state.referralPayoutsList.sortDir = action.payload.sortDir;
    },
    setReferralPayoutsDateFilter: (
      state,
      action: PayloadAction<{
        fromDate: IUserReferalsState['referralPayoutsList']['fromDate'];
        toDate: IUserReferalsState['referralPayoutsList']['toDate'];
      }>,
    ) => {
      state.referralPayoutsList.fromDate = action.payload.fromDate;
      state.referralPayoutsList.toDate = action.payload.toDate;
    },
    referralUsersListSetPage: (state, action: PayloadAction<number>) => {
      state.referralUsersList.currentPage = action.payload;
    },
    setItemsPerReferralUsersListPage: (state, action: PayloadAction<number>) => {
      state.referralUsersList.itemsPerPage = action.payload;
      state.referralUsersList.currentPage = initialState.referralUsersList.currentPage;
    },
    setReferralUsersSearch: (
      state,
      action: PayloadAction<IUserReferalsState['referralUsersList']['search']>,
    ) => {
      state.referralUsersList.search = action.payload;
    },
    setReferralUsersSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserReferalsState['referralUsersList']['sortBy'];
        sortDir: IUserReferalsState['referralUsersList']['sortDir'];
      }>,
    ) => {
      state.referralUsersList.sortBy = action.payload.sortBy;
      state.referralUsersList.sortDir = action.payload.sortDir;
    },
    setReferralUsersDateFilter: (
      state,
      action: PayloadAction<{
        fromDate: IUserReferalsState['referralUsersList']['fromDate'];
        toDate: IUserReferalsState['referralUsersList']['toDate'];
      }>,
    ) => {
      state.referralUsersList.fromDate = action.payload.fromDate;
      state.referralUsersList.toDate = action.payload.toDate;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchReferralStats.pending, (state) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralStats.rejected, (state) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.error;
        state.referralStats = initialState.referralStats;
      })
      .addCase(fetchReferralStats.fulfilled, (state, { payload }) => {
        state.referralStatsStatus = ESliceDataFetchingStatus.success;
        state.referralStats = payload;
      })
      .addCase(fetchReferralUsersList.pending, (state) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralUsersList.rejected, (state) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.error;
        state.referralUsersList = initialState.referralUsersList;
      })
      .addCase(fetchReferralUsersList.fulfilled, (state, { payload }) => {
        state.referralUsersListStatus = ESliceDataFetchingStatus.success;
        state.referralUsersList.users = payload.users;
        state.referralUsersList.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchReferralRewardsList.pending, (state) => {
        state.referralRewardsListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralRewardsList.rejected, (state) => {
        state.referralRewardsListStatus = ESliceDataFetchingStatus.error;
        state.referralRewardsList = initialState.referralRewardsList;
      })
      .addCase(fetchReferralRewardsList.fulfilled, (state, { payload }) => {
        state.referralRewardsListStatus = ESliceDataFetchingStatus.success;
        state.referralRewardsList.rewards = payload.rewards;
        state.referralRewardsList.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchReferralsPayoutsList.pending, (state) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchReferralsPayoutsList.rejected, (state) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.error;
        state.referralPayoutsList = initialState.referralPayoutsList;
      })
      .addCase(fetchReferralsPayoutsList.fulfilled, (state, { payload }) => {
        state.referralPayoutsListStatus = ESliceDataFetchingStatus.success;
        state.referralPayoutsList.payouts = payload.payouts;
        state.referralPayoutsList.pagesQuantity = Math.ceil(payload.count / payload.size);
      }),
});

export const selectReferralStats = (state: RootState) => {
  return {
    ...state.userReferrals.referralStats,
    referralStatsStatus: state.userReferrals.referralStatsStatus,
  };
};

export const selectReferralUsers = (state: RootState) => {
  return {
    ...state.userReferrals.referralUsersList,
    referralUsersStatus: state.userReferrals.referralUsersListStatus,
  };
};

export const selectReferralRewards = (state: RootState) => {
  return {
    ...state.userReferrals.referralRewardsList,
    referralRewardsStatus: state.userReferrals.referralRewardsListStatus,
  };
};

export const selectReferralPayouts = (state: RootState) => {
  return {
    ...state.userReferrals.referralPayoutsList,
    referralPayoutsStatus: state.userReferrals.referralPayoutsListStatus,
  };
};

export const {
  referralRewardsListSetPage,
  setItemsPerReferralRewardsListPage,
  setReferralRewardsSearch,
  setReferralRewardsSort,
  setReferralRewardsDateFilter,
  referralPayoutsListSetPage,
  setItemsPerReferralPayoutsListPage,
  setReferralPayoutsSearch,
  setReferralPayoutsSort,
  setReferralPayoutsDateFilter,
  referralUsersListSetPage,
  setItemsPerReferralUsersListPage,
  setReferralUsersSearch,
  setReferralUsersSort,
  setReferralUsersDateFilter,
} = userReferralSlice.actions;

export const userReferralReducer = userReferralSlice.reducer;
