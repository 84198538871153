import { BorderedWrapper } from 'components/shared';
import { StyledAccuralSchedule } from './styles';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { AccuralDiagram } from 'components/AccuralDiagram';
interface IAccuralScheduleProps {
  children?: React.ReactNode;
}
export const AccuralSchedule = ({ children }: IAccuralScheduleProps) => {
  const { t } = useTranslation();
  const totalAccuredValue = '1.12312312';
  const creditFixTime = '00:00 GMT-1';

  const componentRef = useRef<HTMLDivElement>(null);
  const [hashrateDiagramContainerWidth, setHashrateDiagramContainerWidth] = useState(50);

  useEffect(() => {
    setHashrateDiagramContainerWidth(componentRef.current!.clientWidth);
    if (componentRef.current) {
      window.addEventListener('resize', () => {
        const currentWidth = componentRef.current?.clientWidth;
        if (currentWidth !== hashrateDiagramContainerWidth) {
          setHashrateDiagramContainerWidth((prev) =>
            componentRef.current ? componentRef.current.clientWidth : prev,
          );
        }
      });
    }

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <StyledAccuralSchedule className="accural-schedule">
      <BorderedWrapper>
        <h4 className="accural-schedule-heading">{t('accuralSchedule.heading')}</h4>
        <p className="accural-schedule-credits-fixed">
          {t('accuralSchedule.createdFixed')} {creditFixTime}
        </p>
        <div className="accural-schedule-diagram" ref={componentRef}>
          <AccuralDiagram containerWidth={hashrateDiagramContainerWidth} />
        </div>
        <p className="accural-schedule-total-accured">
          <span className="accural-schedule-total-accured__title">
            {t('accuralSchedule.totalAccured')}
          </span>
          <span className="accural-schedule-total-accured__value">{totalAccuredValue}</span>
        </p>
      </BorderedWrapper>
      {children}
    </StyledAccuralSchedule>
  );
};
