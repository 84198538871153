import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import {
  fetchUserPoolUrls,
  fetchWorkersGroupsList,
  fetchWorkersList,
} from 'store/thunks/userWorkersThunks';
import { IUserPoolUrlData, IWorker, IWorkerGroup, TNormalizedGroups, EWorkerStatus } from 'types';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IUserWorkersState {
  workers: IWorker[];
  status: ESliceDataFetchingStatus;
  groups: IWorkerGroup[];
  normalizedGroups: TNormalizedGroups;
  groupsStatus: ESliceDataFetchingStatus;
  errorMessage: string | null;
  userPoolUrlData: IUserPoolUrlData | null;
  userPoolUrlDataStatus: ESliceDataFetchingStatus;
  pagesQuantity: number;
  itemsPerPage: number;
  currentPage: number;
  filterByActivityStatus: EWorkerStatus | null;
  search: string | null;
  sortBy: string | null;
  sortDir: string | null;
}
const initialState: IUserWorkersState = {
  workers: [],
  status: ESliceDataFetchingStatus.initial,
  groups: [],
  normalizedGroups: { allIDs: [], byID: {} },
  groupsStatus: ESliceDataFetchingStatus.initial,
  errorMessage: null,
  userPoolUrlData: null,
  userPoolUrlDataStatus: ESliceDataFetchingStatus.initial,
  pagesQuantity: 1,
  itemsPerPage: 10,
  currentPage: 1,
  filterByActivityStatus: null,
  search: null,
  sortBy: null,
  sortDir: null,
};
export const userWorkersSlice = createSlice({
  name: 'userWorkers',
  initialState,
  reducers: {
    workersListSetPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setItemsPerWorkersListPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = initialState.currentPage;
    },
    setFilterByActivityStatus: (
      state,
      action: PayloadAction<IUserWorkersState['filterByActivityStatus']>,
    ) => {
      state.filterByActivityStatus = action.payload;
    },
    setSearch: (state, action: PayloadAction<IUserWorkersState['search']>) => {
      state.search = action.payload;
    },
    setSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserWorkersState['sortBy'];
        sortDir: IUserWorkersState['sortDir'];
      }>,
    ) => {
      state.sortBy = action.payload.sortBy;
      state.sortDir = action.payload.sortDir;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchWorkersList.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.workers = payload.workers;
        state.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchWorkersList.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchWorkersList.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch workerks list error';
      })
      .addCase(fetchUserPoolUrls.fulfilled, (state, { payload }) => {
        state.userPoolUrlData = payload.data[0];
        state.userPoolUrlDataStatus = ESliceDataFetchingStatus.success;
      })
      .addCase(fetchUserPoolUrls.pending, (state) => {
        state.userPoolUrlDataStatus = ESliceDataFetchingStatus.success;
      })
      .addCase(fetchUserPoolUrls.rejected, (state, { error }) => {
        state.userPoolUrlDataStatus = ESliceDataFetchingStatus.error;
        console.error('fetchUserPoolUrls error', error);
        state.userPoolUrlData = null;
      })
      .addCase(fetchWorkersGroupsList.fulfilled, (state, { payload }) => {
        const normalizedGroups = payload.reduce<IUserWorkersState['normalizedGroups']>(
          (acc, group) => {
            const id = group.id;
            if (!acc.byID[id]) {
              acc.allIDs.push(id);
              acc.byID[id] = group;
            }
            return acc;
          },
          { allIDs: [], byID: {} },
        );
        state.normalizedGroups = normalizedGroups;
        state.groups = payload;
        state.groupsStatus = ESliceDataFetchingStatus.success;
      })
      .addCase(fetchWorkersGroupsList.pending, (state) => {
        state.groupsStatus = ESliceDataFetchingStatus.loading;
      })
      .addCase(fetchWorkersGroupsList.rejected, (state) => {
        state.groups = initialState.groups;
        state.groupsStatus = ESliceDataFetchingStatus.error;
      }),
});

export const selectWorkers = (state: RootState) => state.userWorkers;

export const selectCurentPage = createSelector(selectWorkers, (state) => {
  return state.currentPage;
});
export const selectPagesQuantity = createSelector(selectWorkers, (state) => {
  return state.pagesQuantity;
});
export const selectItemsPerPage = createSelector(selectWorkers, (state) => state.itemsPerPage);

export const selectPagesData = createSelector(selectWorkers, (state) => {
  const { currentPage, pagesQuantity } = state;
  return { currentPage, pagesQuantity };
});

export const selectWorkersQuantityByStatus = createSelector(selectWorkers, (state) => {
  return state.workers.reduce<{
    ONLINE: number;
    OFFLINE: number;
    INACTIVE: number;
    DELETED: number;
    all: number;
  }>(
    (acc, val) => {
      acc[val.status] += 1;
      return acc;
    },
    { ONLINE: 0, OFFLINE: 0, INACTIVE: 0, all: state.workers.length, DELETED: 0 },
  );
});

export const {
  workersListSetPage,
  setFilterByActivityStatus,
  setItemsPerWorkersListPage,
  setSearch,
  setSort,
} = userWorkersSlice.actions;

export const userWorkersReducer = userWorkersSlice.reducer;
