import { IApiResponse } from './api';

export enum EPayoutStatus {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export type TPayoutsFilterStatus = `${EPayoutStatus}`;

export interface IPayout {
  id: string;
  date: number;
  transactionId: string;
  amount?: string;
  confirmed: boolean;
}

export type TSortPayoutByHeaderTitle = keyof IPayout;

export type TPayoutQuery = {
  page: string;
  size: string;
  sort?: string;
  status?: EPayoutStatus;
  filter?: string;
  order?: string;
  from?: string;
  to?: string;
};

export interface IPayoutsResponse extends IApiResponse {
  data: { result: IPayout[]; page: number; size: number; count: number };
}

export interface IExportPayoutsResponse extends IApiResponse {
  data: string;
}
