import { css } from 'styled-components';

export const StyledSCrollBar = () => css`
  overflow: auto;
  scrollbar-color: ${(props) => props.theme.button3} ${(props) => props.theme.button3};

  &::-webkit-scrollbar {
    width: 0;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent4};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button3 : props.theme.accent1};
  }
`;
