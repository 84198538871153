import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CheckBox,
  Confirmation,
  Form,
  Input,
  Layout,
  Modal,
  NewPasswordInput,
  PasswordInput,
  PhoneInput,
} from 'components';
import {
  StyledCheckBoxGroup,
  StyledCheckBoxLabel,
  StyledCheckMark,
} from 'components/shared/CheckBox/styles';
import { ISignUpUserData, EFromStates } from 'types';
import { AccountRepository } from 'repositories/AccountRepository';

import styled from 'styled-components';
import { validationSchemaRegistration } from 'lib/validataion';
import { EApiResponseStatus } from 'types/api';
const StyledContent = styled.div`
  max-width: 60.6rem;
  margin: auto;
  padding: 2rem 1rem 10rem;
  text-align: center;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  @media screen and (min-width: 768px) {
    padding: 5rem 1rem 12.1rem;
  }
  .recovery-form-container {
    position: relative;
    margin-bottom: 2rem;

    .recovery-form-overlay {
      z-index: 1000;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.9;

      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg3};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
    }
  }
  .login-link-container {
    padding: 0.7rem 0;
    width: max-content;
    margin: auto;
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  .login-link {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
  }
`;

const StyledInputsGroup = styled.div`
  max-width: 39rem;
  margin: auto;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .divider {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
    margin: -1rem;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 2rem;
  }
`;
const StyledFormHeader = styled.p`
  margin-bottom: 2.5rem;
`;

const StyledCheckboxContainer = styled.div`
  max-width: 39rem;
  margin: auto;
  margin-bottom: 3.5rem;

  > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${StyledCheckBoxGroup} {
    padding-left: 0.2rem;
  }

  ${StyledCheckMark} {
    margin-top: 0.4rem;
  }

  ${StyledCheckBoxLabel} {
    align-items: start;
    gap: 1.2rem;
  }
`;

const StyledPromocodeHeading = styled.p`
  padding: 0.7rem 0;
  margin-bottom: 1rem;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text1)};
`;

const StyledPromocodeInputGroup = styled.div`
  max-width: 39rem;
  margin: auto;
  margin-bottom: 2rem;
`;

const StyledSendPromocodeButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.6rem;
`;

const StyledButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 4rem;
  }
`;

export const RegistrationPage = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [formState, setFormState] = useState<EFromStates>(EFromStates.initial);
  interface IRegistrationFormValue {
    userName: string;
    userEmail: string;
    userPhone: string;
    userNewPassword: string;
    userRepeatPassword: string;
    termsAgree: boolean;
    promoCode: string;
    formState: EFromStates;
    formErrors: string;
  }

  const formikSubmitHandler = async (
    values: IRegistrationFormValue,
    helpers: FormikHelpers<IRegistrationFormValue>,
  ) => {
    const { userEmail, userName, userNewPassword, userPhone, promoCode } = values;

    const signUpFormData: ISignUpUserData = {
      name: userName,
      email: userEmail,
      phone: userPhone ? userPhone : undefined,
      password: userNewPassword,
      subscribe: subscribeCheckboxRef.current?.checked,
    };

    if (!!promoCode && promoCode.length > 0) signUpFormData.promoCode = promoCode;

    try {
      setFormState(EFromStates.submitting);
      const result = await AccountRepository.signUp(signUpFormData);
      if (result.status === EApiResponseStatus.FAILURE) throw Error(result.error);
      setShowModal(true);
      setFormState(EFromStates.initial);
    } catch (error) {
      setFormState(EFromStates.failure);
      console.error(error);
      if (error instanceof Error) {
        helpers.setErrors({ formErrors: error.message });
        return;
      }
      helpers.setErrors({ formErrors: 'submit error' });
    }
  };

  const subscribeCheckboxRef = useRef<HTMLInputElement>(null);
  const termsAgreeCheckboxRef = useRef<HTMLInputElement>(null);

  return (
    <Layout home>
      {showModal && (
        <Modal show={showModal}>
          <Confirmation />
        </Modal>
      )}
      <StyledContent>
        <div className="recovery-form-container">
          <Formik
            initialValues={{
              userName: '',
              userEmail: '',
              userPhone: '',
              userNewPassword: '',
              userRepeatPassword: '',
              promoCode: '',
              formState: EFromStates.initial,
              formErrors: '',
              termsAgree: false,
            }}
            onSubmit={formikSubmitHandler}
            validationSchema={validationSchemaRegistration}
          >
            {({
              handleSubmit,
              values,
              handleChange,
              isSubmitting,
              errors,
              touched,
              handleBlur,
              submitCount,
              isValid,
            }) => (
              <Form
                header={
                  <StyledFormHeader className="p1 semibold">
                    {t('forms.registration.header')}
                  </StyledFormHeader>
                }
                onSubmit={handleSubmit}
                formState={formState}
                setFormState={setFormState}
                errorMessage={errors.formErrors}
              >
                <StyledInputsGroup>
                  <Input
                    name="userName"
                    placeholder={t('forms.registration.usernamePlaceHolder')}
                    inValid={!!errors.userName && touched.userName}
                    error={errors.userName}
                    disabled={isSubmitting}
                    value={values.userName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <Input
                    name="userEmail"
                    placeholder={t('forms.registration.emailPlaceHolder')}
                    inValid={!!errors.userEmail && touched.userEmail}
                    error={errors.userEmail}
                    disabled={isSubmitting}
                    value={values.userEmail}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <PhoneInput
                    name="userPhone"
                    inValid={!!errors.userPhone && touched.userPhone}
                    error={errors.userPhone}
                    disabled={isSubmitting}
                    value={values.userPhone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <NewPasswordInput
                    name="userNewPassword"
                    placeholder={t('forms.registration.passwordPlaceholder')}
                    inValid={!!errors.userNewPassword && touched.userNewPassword}
                    disabled={isSubmitting}
                    value={values.userNewPassword}
                    error={errors.userNewPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <PasswordInput
                    name="userRepeatPassword"
                    placeholder={t('forms.registration.passwordRepeatePlaceholder')}
                    onBlur={handleBlur}
                    inValid={!!errors.userRepeatPassword && touched.userRepeatPassword}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    value={values.userRepeatPassword}
                    error={errors.userRepeatPassword}
                  />
                </StyledInputsGroup>
                <StyledButtonsContainer>
                  <Button
                    type="submit"
                    title={t('forms.registration.submitButton')}
                    disabled={isSubmitting || (!isValid && submitCount > 0)}
                  ></Button>
                </StyledButtonsContainer>

                <StyledCheckboxContainer>
                  <CheckBox
                    ref={subscribeCheckboxRef}
                    label={t('forms.registration.subscribeCheckbox')}
                    disabled={isSubmitting}
                  />
                  <CheckBox
                    name="termsAgree"
                    inValid={!!errors.termsAgree && touched.termsAgree}
                    ref={termsAgreeCheckboxRef}
                    checked={values.termsAgree}
                    onChange={handleChange}
                    label={t('forms.registration.agreementCheckbox')}
                    disabled={isSubmitting}
                  />
                </StyledCheckboxContainer>
                <div>
                  <StyledPromocodeHeading className="p2">
                    {t('forms.registration.promocodeHeader')}
                  </StyledPromocodeHeading>
                  <StyledPromocodeInputGroup>
                    <Input
                      name="promoCode"
                      placeholder={t('forms.registration.promocodePlaceholder')}
                      value={values.promoCode}
                      onChange={handleChange}
                    />
                  </StyledPromocodeInputGroup>
                  <StyledSendPromocodeButton>
                    <Button
                      type="submit"
                      title={t('forms.registration.submitPromocodeButton')}
                      disabled
                    ></Button>
                  </StyledSendPromocodeButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="login-link-container">
          <p className="p3">{t('forms.registration.registered')}</p>
          <Link className="login-link" to="/login">
            <span className="p2">{t('forms.registration.login')}</span>
          </Link>
        </div>
      </StyledContent>
    </Layout>
  );
};
