import {
  BorderedWrapper,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  Paginator,
  Search,
} from 'components';
import { StyledReferralPayoutsList } from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { formateDate, formateTime } from 'utils';
import { TSortIReferralPayoutByHeaderTitle } from 'types';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { fetchReferralsPayoutsList } from 'store/thunks/userReferralThunks';
import { useSelector } from 'react-redux';
import {
  selectReferralPayouts,
  referralPayoutsListSetPage,
  setItemsPerReferralPayoutsListPage,
  setReferralPayoutsSearch,
  setReferralPayoutsSort,
  setReferralPayoutsDateFilter,
} from 'store/slices/userReferralSlice';
import { getNextSortDir } from 'utils';
interface IReferralPayoutsListProps {
  children?: React.ReactNode;
}

export const ReferralPayoutsList = ({ children }: IReferralPayoutsListProps) => {
  const { payouts, pagesQuantity, currentPage, itemsPerPage } = useSelector(selectReferralPayouts);
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'ASC' | 'DESC' | 'NONE'>('NONE');

  const dispatch = useAppDispatch();

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(referralPayoutsListSetPage(nextPage));
    dispatch(fetchReferralsPayoutsList());
  };

  const changeItemsPerPageQuantityHandler = (itemsQuantity: number) => {
    dispatch(setItemsPerReferralPayoutsListPage(itemsQuantity));
    dispatch(fetchReferralsPayoutsList());
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    const fromDate = range.from.toISOString();
    const toDate = range.to.toISOString();
    dispatch(
      setReferralPayoutsDateFilter({
        fromDate: fromDate === toDate ? null : fromDate,
        toDate: fromDate === toDate ? null : toDate,
      }),
    );
    dispatch(fetchReferralsPayoutsList());
  };

  const setSearchFilterHandler = (value: string) => {
    dispatch(setReferralPayoutsSearch(value === '' ? null : value));
    dispatch(fetchReferralsPayoutsList());
  };

  const setSortHandler = (sortBy: {
    sortBy: TSortIReferralPayoutByHeaderTitle;
    sortDir: 'ASC' | 'DESC' | 'NONE';
  }) => {
    dispatch(
      setReferralPayoutsSort({
        sortBy: sortBy.sortDir === 'NONE' ? null : sortBy.sortBy,
        sortDir: sortBy.sortDir === 'NONE' ? null : sortBy.sortDir,
      }),
    );
    dispatch(fetchReferralsPayoutsList());
  };

  const sortHandler = (nextSortBy: TSortIReferralPayoutByHeaderTitle) => {
    const nextSortDir = getNextSortDir(sortBy, nextSortBy, sortDir);
    setSortHandler({ sortBy: nextSortBy, sortDir: nextSortDir });
    setSortBy(nextSortBy);
    setSortDir(nextSortDir);
  };

  useEffect(() => {
    dispatch(fetchReferralsPayoutsList());
  }, []);

  return (
    <StyledReferralPayoutsList>
      <BorderedWrapper>
        <div className="referral-list-controls-container">
          <h2 className="referral-list-heading">
            {t('referralsPage.referralPayoutsList.heading')}
          </h2>
          <div className="referral-list-controls">
            <CustomCalendar onChange={changeDateRangeHandler} />
            <Search onChange={setSearchFilterHandler} />
          </div>
        </div>
        <div className="referral-list-container">
          <div className="referral-list-header">
            <div className="referral-list-header-element">
              <div>{t('referralsPage.referralPayoutsList.header.dateTime')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('date')}
                style={{
                  opacity: sortBy === 'date' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'date' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${sortBy === 'date' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element">
              <div>{t('referralsPage.referralPayoutsList.header.amount')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('amount')}
                style={{
                  opacity: sortBy === 'amount' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'amount' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${sortBy === 'amount' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
          </div>
          <div className="referral-list-body">
            {payouts.map((payout) => {
              const elementdate = new Date(payout.date);
              return (
                <div key={payout.id} className="referral-list-row">
                  <div className="referral-list-element date">
                    <span>{formateDate(elementdate)}</span>
                    <span className="time">
                      &nbsp;-&nbsp;
                      {`${formateTime(elementdate)}`}
                    </span>
                  </div>
                  <div className="referral-list-element sum">{payout.amount}</div>
                </div>
              );
            })}
          </div>
          {pagesQuantity > 0 && (
            <div className="pagination-container">
              <Paginator
                currentPage={currentPage}
                pageQuantity={pagesQuantity}
                onChange={changeCurrentPageHandler}
              />
              <DisplayItemsPerPageSelect
                onChange={changeItemsPerPageQuantityHandler}
                currentItemsPerPageQuantity={itemsPerPage}
              />
            </div>
          )}
        </div>
      </BorderedWrapper>
    </StyledReferralPayoutsList>
  );
};
