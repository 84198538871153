import { Middleware } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { toggleTheme } from 'store/slices/userSettings';
import { THEME_KEY } from 'constants/appConstants';

export const themeMiddleware: Middleware = (store) => (next) => async (action) => {
  const result = next(action);

  if (toggleTheme.match(action)) {
    const state = store.getState();
    const theme = state.userSettings.theme;
    localStorage.setItem(THEME_KEY, theme);

    const { token } = state.auth;
    if (token) UserRepository.setUserTheme({ theme }, token);
  }

  return result;
};
