import {
  BorderedWrapper,
  Button,
  Hint,
  Modal,
  ReferalCalculatorDialog,
  ReferralLevelsDialog,
} from 'components';
import { StyledReferrralLevels } from './styles';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useAppSelector } from 'store/store';
// import { selectReferralStats } from 'store/slices/userReferralSlice';
import { ESliceDataFetchingStatus } from 'types/common';
import { formateHashUnits } from 'utils';
interface IReferrralLevelsProps {
  children?: React.ReactNode;
}
export const ReferrralLevels = ({ children }: IReferrralLevelsProps) => {
  const { t } = useTranslation();

  const { referralStatsStatus, referralStats } = useAppSelector((state) => state.userReferrals);
  const { referralCurrentRate, referralLevel, referralMaxRate } = referralStats;

  const [isCalcModalOpen, setIsCalcModalOpen] = useState(false);
  const [isLevelsModalOpen, setIsLevelsModalOpen] = useState(false);
  const currentRateParagraphTexts = t('referralsPage.levels.currentRate', {
    returnObjects: true,
  }) as string[];

  return (
    <StyledReferrralLevels>
      <Modal show={isCalcModalOpen}>
        <ReferalCalculatorDialog
          onClose={() => setTimeout(() => setIsCalcModalOpen(false), 500)}
          show={isCalcModalOpen}
        />
      </Modal>
      <Modal show={isLevelsModalOpen}>
        <ReferralLevelsDialog
          onClose={() => setTimeout(() => setIsLevelsModalOpen(false), 500)}
          show={isLevelsModalOpen}
        />
      </Modal>
      <BorderedWrapper>
        {referralStatsStatus === ESliceDataFetchingStatus.loading ? (
          'Loading...'
        ) : (
          <div className="content">
            <h2 className="levels-heading">
              {t('referralsPage.levels.heading')}
              <div className="hint-container">
                <Hint onClick={() => setIsLevelsModalOpen(true)}>?</Hint>
              </div>
            </h2>
            <div className="level-value">{referralLevel ? referralLevel : 'n/a'}</div>
            <div className="current-hash-rate">
              <p className="current-hash-rate-title">{currentRateParagraphTexts[0]}</p>
              <p className="current-hash-rate-value">
                <span>
                  {referralCurrentRate ? formateHashUnits(referralCurrentRate) : 'n/a'} TH/s
                </span>{' '}
                <span>{currentRateParagraphTexts[1]}</span>{' '}
                <span>{referralMaxRate ? formateHashUnits(referralMaxRate) : 'n/a'} TH/s</span>
              </p>
            </div>
            <div className="calc-button">
              <Button
                title={t('referralsPage.levels.calculatorModalButton')}
                onClick={() => setIsCalcModalOpen(true)}
              />
            </div>
          </div>
        )}
      </BorderedWrapper>
    </StyledReferrralLevels>
  );
};
