import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchPayoutsList } from 'store/thunks/userPayoutThunks';
import { EPayoutStatus, IPayout } from 'types';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IUserPayoutState {
  payouts: IPayout[];
  status: ESliceDataFetchingStatus;
  pagesQuantity: number;
  itemsPerPage: number;
  currentPage: number;
  errorMessage: string | null;
  filterByConfirmationStatus: EPayoutStatus | null;
  search: string | null;
  sortBy: string | null;
  sortDir: string | null;
  fromDate: string | null;
  toDate: string | null;
}

const initialState: IUserPayoutState = {
  payouts: [],
  status: ESliceDataFetchingStatus.initial,
  pagesQuantity: 1,
  itemsPerPage: 10,
  currentPage: 1,
  errorMessage: null,
  filterByConfirmationStatus: null,
  search: null,
  sortBy: null,
  sortDir: null,
  fromDate: null,
  toDate: null,
};

export const userPayoutsSlice = createSlice({
  name: 'userPayouts',
  initialState,
  reducers: {
    payoutsListSetPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setItemsPerPayoutsListPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = initialState.currentPage;
    },
    setFilterByConfirmationStatus: (
      state,
      action: PayloadAction<IUserPayoutState['filterByConfirmationStatus']>,
    ) => {
      state.filterByConfirmationStatus = action.payload;
    },
    setSearch: (state, action: PayloadAction<IUserPayoutState['search']>) => {
      state.search = action.payload;
    },
    setSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserPayoutState['sortBy'];
        sortDir: IUserPayoutState['sortDir'];
      }>,
    ) => {
      state.sortBy = action.payload.sortBy;
      state.sortDir = action.payload.sortDir;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{
        fromDate: IUserPayoutState['fromDate'];
        toDate: IUserPayoutState['toDate'];
      }>,
    ) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchPayoutsList.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.payouts = payload.payouts;
        state.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchPayoutsList.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchPayoutsList.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch payouts list error';
      }),
});

export const selectPayouts = (state: RootState) => state.userPayouts.payouts;

export const {
  payoutsListSetPage,
  setFilterByConfirmationStatus,
  setItemsPerPayoutsListPage,
  setSearch,
  setSort,
  setDateFilter,
} = userPayoutsSlice.actions;
export const userPayoutsReducer = userPayoutsSlice.reducer;
