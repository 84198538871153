import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import cn from 'classnames';
import { v4 as uuid } from 'uuid';
import { getCurlyPath, getFormatedDate, roundToHundred } from 'utils';
import { Button } from 'components/shared';
import { TPoint } from 'types/hashrate';
import { StyledOverallHashRateChart, StyledOverallHashRateChartElement } from './styles';
import { Routes } from 'lib';
import { useSelector } from 'react-redux';
import { fetchCommonHashrate } from 'store/thunks/commonDataThunks';
import { RootState, useAppDispatch } from 'store/store';

import * as d3 from 'd3';

export const OverallHashRateChart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { commonHashRate } = useSelector((state: RootState) => state.commonData);
  const [contentWidth, setContentWidth] = useState(295);
  const dailyHashRates = commonHashRate.map(({ acceptedHashrate }, i) => {
    const date = new Date();
    date.setDate(date.getDate() + 1 * i);
    return { hashRate: acceptedHashrate, date: getFormatedDate(date) };
  });

  const contentRef = useRef<HTMLDivElement>(null);

  const letsStartButtonHandler = () => navigate(Routes.statisticsPath());

  useEffect(() => {
    //TODO: set up dinamic dates for fetching
    //https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-5
    dispatch(fetchCommonHashrate({ from: 23232, to: 23232 }));
  }, []);

  useEffect(() => {
    setContentWidth(contentRef.current!.clientWidth);
    if (contentRef.current) {
      window.addEventListener('resize', () => {
        setContentWidth((prev) => (contentRef.current ? contentRef.current.clientWidth : prev));
      });
    }

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <StyledOverallHashRateChart>
      <div className="content" ref={contentRef}>
        {dailyHashRates.length > 0 && (
          <>
            <h2 className="heading">{t('mainPage.hashRate.heading')}</h2>
            <div className="hashrate-chart-container">
              <div className="hashrate-chart">
                <HashrateChart width={contentWidth} dailyHashrates={dailyHashRates} />
              </div>
              <div className="hashrate-chart-legend">
                <div className="hashrate-chart-legend-element hashrate">
                  {t('mainPage.hashRate.chartLegend')}
                </div>
              </div>
            </div>
          </>
        )}
        <Button
          onClick={letsStartButtonHandler}
          className="start-mining-button"
          title={t('mainPage.hashRate.startMiningButton')}
        />
      </div>
    </StyledOverallHashRateChart>
  );
};

interface IHashrateChartProps {
  width: number;
  dailyHashrates: { date: string; hashRate: number }[];
}

const HashrateChart = ({ width, dailyHashrates }: IHashrateChartProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [chartWidth, setChartWidth] = useState(width);

  const hashRateValueLabel = 'EH/s';
  const yAxisUnit = 'E';

  const chartRef = useRef<SVGSVGElement>(null);

  const containerViewbox = `0 0 ${containerWidth} ${containerHeight}`;

  const chartHeight = 220;
  const chartViewbox = `0 0 ${chartWidth} ${chartHeight}`;
  const chartTopMargin = 18;

  const unitsYUnitHeight = 25;
  const untisYMarginBottom = 30;
  const unitsYBlockWidth = containerWidth > 295 ? 75 : 40;
  const untisYUnitTextPadding = containerWidth > 295 ? 15 * (containerWidth > 920 ? 2 : 1) : 5;
  const untisYUnitsQuantity = dailyHashrates.length > 0 ? 5 : 1;
  const unitsYBlockHeight =
    unitsYUnitHeight * untisYUnitsQuantity + untisYMarginBottom * untisYUnitsQuantity - 1;

  const defaultDaysToShow = 120;
  const daysPerPeriod = 30;
  const axisXUnitsValuesQuantity =
    dailyHashrates.length > 0 ? defaultDaysToShow / daysPerPeriod + 1 : 0;
  const unitsXUnitWidth = containerWidth > 295 ? (containerWidth > 700 ? 136 : 90) : 60;
  const untisXUnitMarginRight =
    (chartWidth - unitsXUnitWidth * axisXUnitsValuesQuantity) / (axisXUnitsValuesQuantity - 1);
  const unitsXLineHeight = containerWidth > 950 ? 25 : 50;
  const untiXRectHeight = containerWidth > 950 ? unitsXLineHeight : containerWidth > 295 ? 40 : 25;
  const unitsXLineWidth = chartWidth;
  const unitsXLineX = unitsYBlockWidth;
  const unitsXLineY = containerHeight - unitsXLineHeight;

  const chartMainDataWidth = chartWidth - unitsXUnitWidth;
  const chartDataUnitWidth = chartMainDataWidth / defaultDaysToShow;
  const chartDataValuesQuantity = Math.ceil(chartWidth / chartDataUnitWidth);

  const chartExtendedDataValuesHalfQuantity = Math.ceil(
    (chartDataValuesQuantity - defaultDaysToShow) / 2,
  );

  const slicedDayliHashRates = dailyHashrates.slice(
    0,
    defaultDaysToShow + chartExtendedDataValuesHalfQuantity * 2,
  );
  const hashrates = slicedDayliHashRates
    .slice(0, defaultDaysToShow + chartExtendedDataValuesHalfQuantity * 2)
    .map((dailyHashrate) => dailyHashrate.hashRate);
  const hashratesMax = Math.max(...hashrates);
  const hashratesMaxYUnitValue = roundToHundred(hashratesMax);

  const axisYValuesIndexs: number[] = [];

  for (let i = 0; i < axisXUnitsValuesQuantity; i += 1) {
    axisYValuesIndexs.push(chartExtendedDataValuesHalfQuantity + daysPerPeriod * i);
  }

  const hashratePoints: TPoint[] = hashrates.map((hashrate, i, hashrates) => ({
    x: (chartWidth / hashrates.length) * i,
    y: Math.floor(hashrate * (chartHeight / hashratesMax)),
  }));

  const axisYUnitsValues = [...Array(untisYUnitsQuantity)].map((_, i, { length }) =>
    hashratesMaxYUnitValue > 0
      ? hashratesMaxYUnitValue - (hashratesMaxYUnitValue / (length - 1)) * i
      : 0,
  );

  const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number | null>(null);

  const mouseOverchartHandler: React.MouseEventHandler = (event) => {
    if (chartRef.current) {
      const chartBouningRect = chartRef.current.getBoundingClientRect();
      const chartMouseX = event.clientX - chartBouningRect.x;
      const index = Math.floor(chartMouseX / (chartWidth / slicedDayliHashRates.length));
      setCurrentSelectedIndex(index);
    }
  };

  const axisXElementClickHandler =
    (index: number): React.MouseEventHandler =>
    () => {
      setCurrentSelectedIndex(index);
    };

  useEffect(() => {
    setContainerHeight(dailyHashrates.length > 0 ? 290 : 50);
    if (width >= 1320) {
      setContainerWidth(1290);
      setChartWidth(1150);
      return;
    }
    if (width >= 950) {
      setContainerWidth(920);
      setChartWidth(770);
      return;
    }
    if (width >= 700) {
      setContainerWidth(670);
      setContainerHeight(285);
      setChartWidth(520);
      return;
    }

    setChartWidth(width - (width < 335 ? 100 : 150));

    setContainerWidth(width);
  }, [width, dailyHashrates]);

  const popupHieght = 65;
  const getPopupY = (pointYValue: number, popupHeight: number) => {
    const popupX = chartHeight - pointYValue - popupHeight - 15;
    if (popupX < 0) {
      return chartHeight - pointYValue + 25;
    }
    return popupX;
  };

  const getPopupX = (x: number, popupWidht: number, chartWidth: number) => {
    const popupHalfWidht = popupWidht / 2;
    return x < popupHalfWidht
      ? 5
      : x > chartWidth - popupHalfWidht
        ? chartWidth - popupWidht - 5
        : x - popupHalfWidht;
  };

  const getPointerLineHeight = (heightValue: number) => {
    if (chartHeight - heightValue > popupHieght + 15) {
      return heightValue * 0.95 + popupHieght;
    }
    return heightValue * 0.95;
  };
  return (
    <StyledOverallHashRateChartElement>
      <svg
        className="svg-chart-container"
        width={containerWidth}
        height={containerHeight}
        viewBox={containerViewbox}
        fill="transparent"
      >
        {dailyHashrates.length > 0 && (
          <svg
            className="svg-chart"
            width={chartWidth}
            height={chartHeight}
            viewBox={chartViewbox}
            x={unitsYBlockWidth}
            y={chartTopMargin}
          >
            {[...Array(untisYUnitsQuantity)].map((_, i) => (
              <path
                className="chart-unit-y-line"
                key={uuid()}
                d={`M ${0},${i * 55} H${chartWidth}`}
              ></path>
            ))}
            {hashratePoints.length > 0 && (
              <svg
                ref={chartRef}
                width={chartWidth}
                height={chartHeight}
                viewBox={`0 0 ${chartWidth} ${chartHeight}`}
                x={0}
                y={0}
                onMouseMove={mouseOverchartHandler}
              >
                <rect width="100%" height="100%" fill="red" opacity={0} />

                <path
                  className="chart-line-stroke"
                  strokeWidth={4}
                  d={getCurlyPath(
                    { xEnd: chartWidth, yZero: chartHeight },
                    hashratePoints,
                    chartHeight,
                  )}
                />
                <path
                  className="chart-line-fill"
                  strokeWidth={0}
                  d={getCurlyPath(
                    { xEnd: chartWidth, yZero: chartHeight },
                    hashratePoints,
                    chartHeight,
                    true,
                  )}
                />
                {currentSelectedIndex && (
                  <>
                    <circle
                      fill={theme.accent1}
                      cx={(currentSelectedIndex * chartWidth) / slicedDayliHashRates.length}
                      cy={chartHeight - hashratePoints[currentSelectedIndex]?.y * 0.95}
                      r={8.5}
                    />
                    <path
                      stroke={theme.accent1}
                      strokeWidth={1}
                      d={`M${
                        (currentSelectedIndex * chartWidth) / slicedDayliHashRates.length
                      },${chartHeight} v-${getPointerLineHeight(
                        hashratePoints[currentSelectedIndex]?.y,
                      )}`}
                    />

                    <g className="hashrate-popup">
                      <rect
                        className="hashrate-popup-body"
                        width={200}
                        height={popupHieght}
                        rx={5}
                        x={getPopupX(
                          (currentSelectedIndex * chartWidth) / slicedDayliHashRates.length,
                          200,
                          chartWidth,
                        )}
                        y={getPopupY(hashratePoints[currentSelectedIndex]?.y, popupHieght)}
                      />
                      <text
                        className="hashrate-popup-date"
                        x={
                          getPopupX(
                            (currentSelectedIndex * chartWidth) / slicedDayliHashRates.length,
                            200,
                            chartWidth,
                          ) + 15
                        }
                        y={getPopupY(hashratePoints[currentSelectedIndex]?.y, popupHieght) + 25}
                        fill="white"
                      >
                        {slicedDayliHashRates[currentSelectedIndex]?.date}
                      </text>
                      <text
                        className="hashrate-popup-value"
                        x={
                          getPopupX(
                            (currentSelectedIndex * chartWidth) / slicedDayliHashRates.length,
                            200,
                            chartWidth,
                          ) + 15
                        }
                        y={getPopupY(hashratePoints[currentSelectedIndex]?.y, popupHieght) + 50}
                        fill="white"
                      >
                        {t('mainPage.hashRate.chartValueLabel')}{' '}
                        {slicedDayliHashRates[currentSelectedIndex]?.hashRate} {hashRateValueLabel}
                      </text>
                    </g>
                  </>
                )}
              </svg>
            )}
          </svg>
        )}

        <g className="chart-unit-y">
          <rect x={0} width={unitsYBlockWidth} height={unitsYBlockHeight}></rect>
          {containerWidth >= 335 && (
            <rect
              x={containerWidth - unitsYBlockWidth}
              width={unitsYBlockWidth}
              height={unitsYBlockHeight}
            ></rect>
          )}
          {axisYUnitsValues.map((value, i) => (
            <Fragment key={uuid()}>
              <g>
                <rect
                  x={0}
                  y={i * (unitsYUnitHeight + untisYMarginBottom)}
                  height={unitsYUnitHeight}
                  width={unitsYBlockWidth}
                ></rect>
                <text
                  className="chart-unit-y-text"
                  x={unitsYBlockWidth - untisYUnitTextPadding}
                  y={(i + 1) * unitsYUnitHeight + i * untisYMarginBottom - 7}
                  textAnchor="end"
                >
                  {value}
                  {yAxisUnit}
                </text>
              </g>
              {containerWidth >= 335 && (
                <g>
                  <rect
                    x={containerWidth - unitsYBlockWidth}
                    y={i * (unitsYUnitHeight + untisYMarginBottom)}
                    height={unitsYUnitHeight}
                    width={unitsYBlockWidth}
                  ></rect>
                  <text
                    className="chart-unit-y-text"
                    x={containerWidth - unitsYBlockWidth + untisYUnitTextPadding}
                    y={(i + 1) * unitsYUnitHeight + i * untisYMarginBottom - 7}
                    textAnchor="start"
                  >
                    {value}
                    {yAxisUnit}
                  </text>
                </g>
              )}
            </Fragment>
          ))}
        </g>

        <g className="chart-unit-x">
          <rect height={unitsXLineHeight} width={unitsXLineWidth} x={unitsXLineX} y={unitsXLineY} />
          {axisYValuesIndexs.map((valueIndex, i) => (
            <g
              key={uuid()}
              className={cn('rotate', currentSelectedIndex === valueIndex && 'selected')}
              onClick={axisXElementClickHandler(valueIndex)}
            >
              <rect
                x={unitsXLineX + (unitsXUnitWidth + untisXUnitMarginRight) * i}
                y={unitsXLineY + (unitsXLineHeight - untiXRectHeight) / 2}
                rx={untiXRectHeight / 2}
                height={untiXRectHeight}
                width={unitsXUnitWidth}
              ></rect>
              <text
                className="chart-unit-x-text"
                x={
                  unitsXLineX + (unitsXUnitWidth + untisXUnitMarginRight) * i + unitsXUnitWidth / 2
                }
                y={unitsXLineY + unitsXLineHeight / 2 + 2}
                fontSize={'1.6rem'}
                dominantBaseline="middle"
                textAnchor="middle"
              >
                {slicedDayliHashRates[valueIndex]?.date}
              </text>
            </g>
          ))}
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1_13955"
            x1="570"
            y1="31.5"
            x2="569.5"
            y2="208.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={theme.accent1} stopOpacity="0.69" />
            <stop offset="1" stopColor={theme.bg1} stopOpacity="0.24" />
          </linearGradient>
          <linearGradient
            id="paint0_linear_1_17096"
            x1="570"
            y1="28.0576"
            x2="570"
            y2="235.216"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={theme.text4} stopOpacity="0.49" />
            <stop offset="1" stopColor={theme.text4} stopOpacity="0" />
          </linearGradient>
          <filter
            id="filter0_d_1_17098"
            x="0.664062"
            y="0"
            width="1163.3"
            height="131"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.615686 0 0 0 0 0.509804 0 0 0 0 0.909804 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_17098" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_17098"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </StyledOverallHashRateChartElement>
  );
};
