import styled from 'styled-components';
import { darkTheme } from 'themes';
export const StyledEditWorkersGroupContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledEditWorkersGroupDialog = styled.dialog`
  max-height: 100dvh;
  overflow: auto;
  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  position: relative;
  border-radius: 15px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background-color: ${(props) => props.theme.bg3};
  width: 63rem;

  padding: 2rem 3rem;

  .submit-error {
    position: absolute;
    right: 10%;

    font-size: 1.8rem;
    color: ${darkTheme.errorRed};
  }

  .submit-success {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    opacity: 0.95;
    background-color: ${(props) => props.theme.bg1};
    color: ${(props) => props.theme.button1};
  }

  .heading {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    margin-bottom: 3rem;
  }
  .edit-groups-save-button {
    margin: auto;
  }

  .delete-mode-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .select-group-to-move-workers {
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1};
    border-radius: 20px;
    padding: 1rem 2rem;

    font-size: 1.4rem;
    font-weight: 500;
    line-height: 150%; /* 27px */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 768px) {
      padding: 0.8rem;
      padding-left: 3.3rem;
      border-radius: 500px;
      /* font-size: 1.8rem; */
      flex-direction: row;
    }

    .groups-to-move-selector {
      cursor: pointer;
      position: relative;
      z-index: 1;
      border-radius: 500px;
      background-color: var(--Dark-mode-buttons-1, #8de24a);
      padding: 0.5rem 1rem;

      color: ${(props) => props.theme.button2};
      text-align: center;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 150%; /* 24px */

      display: flex;
      gap: 1rem;
      align-items: center;

      .selected-group-to-move-to {
        position: relative;
        z-index: 1;
        width: 8.5rem;
        text-align: center;

        white-space: nowrap;
        /* max-width: 90%; */
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .arrow-down-icon {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        width: 1.5rem;

        > * {
          width: 100%;
          fill: currentColor;
        }
      }
      .groups-to-move-selector-dropdown {
        position: absolute;
        z-index: 0;
        background-color: inherit;
        border-radius: 17px;
        width: 100%;
        max-height: 10rem;
        overflow: hidden;

        left: 0;
        top: 0;
        padding-bottom: 1rem;
        padding-top: 2.8rem;
        cursor: pointer;

        &_items {
          max-height: 6.5rem;
          overflow: auto;
          text-align: start;
          padding: 1rem;
          padding-top: 0rem;

          /* background-color: red; */
        }

        &_item {
          white-space: nowrap;
          max-width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
`;

export const StyledGroupEditorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
`;

export const StyledGroupEditor = styled.div``;
