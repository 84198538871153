import { useTranslation } from 'react-i18next';
import { StyledTimezoneSelect } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { type ITimezone } from 'react-timezone-select';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { RootState, useAppDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import { setTimeZone } from 'store/slices/userSettings';
import { UserRepository } from 'repositories/UserRepository';
import toast from 'react-hot-toast';
import { EApiResponseStatus } from 'types/api';

const labelStyle = 'original';

export const CustomTimezoneSelect = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { token } = useSelector((state: RootState) => state.auth);
  const { timeZone } = useSelector((state: RootState) => state.userSettings);

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    timezones: allTimezones,
  });

  const parsedTimeZone = parseTimezone(
    timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  );

  const [isSelecting, setIsSelecting] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const filteredOptions = options.filter((option) =>
    option.value.toLowerCase().includes(searchValue.toLowerCase()),
  );

  const selectTimezoneHandler = (tz: ITimezone) => async () => {
    dispatch(setTimeZone(parseTimezone(tz).value));
    setIsSelecting(false);
    setSearchValue('');

    if (token) {
      try {
        const res = await UserRepository.setTimezone({ timeZone: parseTimezone(tz).value }, token);
        if (res.status !== EApiResponseStatus.SUCCESS) throw Error(res.error);
      } catch (e) {
        if (e instanceof Error) {
          toast.error(t('forms.formStatus.failure'));
        }
      }
    }
  };

  const toggleSelectingHandler = () => {
    setIsSelecting((prev) => !prev);
  };

  const setNotInSelectingHandler = () => {
    setSearchValue('');
    setIsSelecting(false);
  };

  const searchFilterHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (isSelecting) {
      inputRef.current?.focus();
    }
  }, [isSelecting]);

  return (
    <StyledTimezoneSelect onMouseLeave={setNotInSelectingHandler}>
      <label className="timezone-input-label">{t('settingsPage.timezone.label')}</label>
      <div className="timezone-select-container">
        <div className="timezone-select-value-container">
          <input
            ref={inputRef}
            className={cn('timezone-select-value-search-input', isSelecting && 'selecting')}
            onChange={searchFilterHandler}
            value={searchValue}
            placeholder={t('settingsPage.timezone.searchPlaceholder')}
          />
          <button
            className={cn('timezone-select-value-selected', isSelecting && 'selecting')}
            onClick={toggleSelectingHandler}
          >
            <span className="timezone-select-value-selected__hint">
              {t('settingsPage.timezone.valueHint')}
            </span>
            <span className="timezone-select-value-selected__value">
              {parsedTimeZone?.label?.split(') ')[1]}
            </span>
          </button>
          <button
            className="timezone-select-value-dropdown-button"
            onClick={toggleSelectingHandler}
          >
            <ArrowDownIcon className="icon" />
          </button>
        </div>
        <div className={cn('timezone-select__dropdown-container', isSelecting && 'selecting')}>
          <div className="timezone-select__dropdown">
            {filteredOptions.map((option) => (
              <button
                key={option.value}
                className={cn(
                  'timezone-select__option',
                  parsedTimeZone.value === option.value && 'selected',
                )}
                onClick={selectTimezoneHandler(option)}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    </StyledTimezoneSelect>
  );
};
