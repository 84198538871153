import {
  StyledPeriodSelector,
  StyledSelectContainer,
} from 'components/shared/PeriodSelector/styles';
import styled from 'styled-components';
import { darkTheme } from 'themes';

export const StyledReferalCalculatorDialogContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 1rem;
  padding-top: 8rem;

  @media screen and (min-width: 768px) {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const StyledReferalCalculatorDialogOverlay = styled.div`
  position: absolute;
  opacity: 0;
  background: ${darkTheme.accent2};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: opacity 0.5s;
  &.show {
    opacity: ${(props) => (props.theme.id === 'dark' ? '0.39' : '0.75')};
  }
`;
export const StyledReferalCalculatorDialog = styled.dialog`
  position: relative;
  min-height: 30rem;

  border-radius: 15px;
  box-shadow: 0px 31px 20px 0px rgba(0, 0, 0, 0.25);

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};

  background-color: ${(props) => props.theme.bg3};

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  padding: 2rem;
  padding-bottom: 4rem;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  overflow: auto;
  max-height: calc(100dvh - 8rem);
  max-width: 42.5rem;
  margin: auto;

  @media screen and (min-width: 768px) {
    max-width: 81.5rem;
    padding: 1.2rem 1.2rem 3rem 2rem;
    max-height: 100dvh;
  }

  @media screen and (min-width: 1024px) {
    padding: 3rem;
  }

  .calculator-dialog-heading {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 37.5px */

    @media screen and (min-width: 768px) {
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 150%;
      margin-bottom: 4rem;
    }
  }

  .calculator-dialog-references {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4rem;
    }
    .calculator-dialog-reference {
      .calculator-dialog-reference-label {
        display: block;
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
        margin-bottom: 0.5rem;
        padding: 0 1rem;

        @media screen and (min-width: 768px) {
          font-size: 1.6rem;
          line-height: 150%; /* 24px */
        }
      }
      .calculator-dialog-reference-input-group {
        display: flex;
        gap: 0.2rem;
        .calculator-dialog-reference-input {
          border-radius: 94px 0px 0px 94px;
          border: none;
          color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text2)};
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 37.5px */
          width: 15.5rem;
          padding: 0.5rem 2rem 0.5rem 2.5rem;
          outline: none;

          @media screen and (min-width: 768px) {
            font-size: 25px;
            line-height: 150%; /* 37.5px */
            padding: 0.5rem 2rem 0.5rem 2.5rem;
          }
        }
        .calculator-dialog-reference-input-units {
          border-radius: 0px 94px 94px 0px;
          background-color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent1};
          width: 6.9rem;
          color: ${(props) => props.theme.text2};
          text-align: center;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 600;
          line-height: 150%; /* 37.5px */
          padding: 6px 20px 6px 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (min-width: 768px) {
            font-size: 2.5rem;
            line-height: 150%; /* 37.5px */
            padding: 0.5rem 2rem 0.5rem 2.5rem;
          }
        }
      }
    }
  }
  .calculator-dialog-period {
    ${StyledPeriodSelector} {
      max-width: 100%;
    }
    ${StyledSelectContainer} {
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent1};
    }
    margin-bottom: 7rem;
  }
  .calculator-dialog-income {
    .calculator-dialog-income-label {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.text1)};
      text-align: center;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 24px */
      @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
      }
    }
    .calculator-dialog-income-amount {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: 768px) {
        margin-bottom: 1rem;
      }

      .calculator-dialog-income-amount-btc {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 37.5px */
      }
      .calculator-dialog-income-amount-usd {
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
    .calculator-dialog-income-note {
      text-align: center;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
    }
  }
`;
export const StyledCloseButton = styled.button`
  position: absolute;
  right: 0.8rem;
  top: 0.6rem;

  display: block;
  width: 3rem;
  height: 3rem;

  @media screen and (min-width: 768px) {
    right: 1.2rem;
    top: 1.2rem;
  }

  > * {
    fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.button2)};
  }

  &:hover,
  &:active {
    > * {
      fill: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
    }
  }
`;
