import { BorderedWrapper, Paginator, DisplayItemsPerPageSelect } from 'components';
import { StyledWorkersListContainer } from './styles';
import { WorkersList } from '../WorkersList';
import { memo, useEffect } from 'react';
import { IWorker } from 'types';
import { RootState, useAppDispatch } from 'store/store';
import { fetchWorkersGroupsList, fetchWorkersList } from 'store/thunks/userWorkersThunks';
import { WorkerControls } from '../WorkerControls';
import { GET_WORKERS_TIMEOUT_MS } from 'constants/appConstants';
import {
  selectCurentPage,
  selectPagesQuantity,
  workersListSetPage,
  selectItemsPerPage,
  setItemsPerWorkersListPage,
  setSort,
} from 'store/slices/userWorkersSlice';
import { useSelector } from 'react-redux';
export const WorkersListContainerComponent = () => {
  const dispatch = useAppDispatch();

  const pagesQuantity = useSelector((state: RootState) => selectPagesQuantity(state));
  const currentPage = useSelector((state: RootState) => selectCurentPage(state));
  const itemsPerPage = useSelector((state: RootState) => selectItemsPerPage(state));

  const setSortHandler = (sortBy: { sortBy: keyof IWorker; sortDir: 'ASC' | 'DESC' | 'NONE' }) => {
    dispatch(
      setSort({
        sortBy: sortBy.sortDir === 'NONE' ? null : sortBy.sortBy,
        sortDir: sortBy.sortDir === 'NONE' ? null : sortBy.sortDir,
      }),
    );
    dispatch(fetchWorkersList());
  };

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(workersListSetPage(nextPage));
    dispatch(fetchWorkersList());
  };

  const changeItemsPerPageQuantityHAndler = (itemsQuantity: number) => {
    dispatch(setItemsPerWorkersListPage(itemsQuantity));
    dispatch(fetchWorkersList());
  };

  const getWorkersList = () => {
    dispatch(fetchWorkersList());
    dispatch(fetchWorkersGroupsList());

    return setTimeout(() => {
      getWorkersList();
    }, GET_WORKERS_TIMEOUT_MS);
  };

  useEffect(() => {
    const timeout = getWorkersList();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <StyledWorkersListContainer>
      <BorderedWrapper>
        <WorkerControls />
        <WorkersList onSort={setSortHandler} />
        {pagesQuantity > 0 && (
          <div className="pagination-container">
            <Paginator
              onChange={changeCurrentPageHandler}
              currentPage={currentPage}
              pageQuantity={pagesQuantity}
            />
            <DisplayItemsPerPageSelect
              onChange={changeItemsPerPageQuantityHAndler}
              currentItemsPerPageQuantity={itemsPerPage}
            />
          </div>
        )}
      </BorderedWrapper>
    </StyledWorkersListContainer>
  );
};

export const WorkersListContainer = memo(WorkersListContainerComponent);
