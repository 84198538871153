export const VALIDATION_ERROR_USER_NAME_SHORT = 'validationErrorUserNameShort';
export const VALIDATION_ERROR_USER_NAME_LONG = 'validationErrorUserNameLong';
export const VALIDATION_ERROR_USER_NAME_REQUIRED = 'validationErrorNameRequired';
export const VALIDATION_ERROR_USER_EMAIL_REQUIRED = 'validationErrorEmailRequired';
export const VALIDATION_ERROR_USER_EMAIL_INVALID = 'validationErrorNotEmail';
export const VALIDATION_ERROR_USER_PHONE_REQUIRED = 'validationErrorPhoneRequired';
export const VALIDATION_ERROR_USER_PHONE_INVALID = 'validationErrorPhoneInvalid';
export const VALIDATION_ERROR_USER_PASSWORD_REQUIRED = 'validationErrorPasswordRequired';
export const VALIDATION_ERROR_USER_PASSWORD_SHORT = 'validationErrorPasswordShort';
export const VALIDATION_ERROR_USER_REPEAT_PASSWORD_UNEQUAL = 'validationErrorRepeatPasswordUneqaul';
export const VALIDATION_ERROR_USER_NAME_OR_PHONE_REQUIRED = 'validationErrorEmailPhoneRequired';
export const VALIDATION_ERROR_TERMSAGREE_SHOULD_ACCEPTED = 'validationAgreeShouldAccepted';
export const VALIDATION_ERROR_USER_TELEGRAM_REQUIRED = 'validationErrorTGRequired';
export const VALIDATION_ERROR_USER_TELEGRAM_INVALID = 'ValidationErrorTGInvalid';
export const VALIDATION_ERROR_USER_BTCAddress_REQUIRED = 'ValidataionErrorBTCAddressRequired';
export const VALIDATION_ERROR_ADMIN_YESTERDAY_COST_REQUIRED =
  'ValidationErrorYesterdayCostRequired';
export const VALIDATION_ERROR_ADMIN_NEWBIE_COST_REQUIRED = 'ValidationErrorNewbieCostRequired';
export const VALIDATION_ERROR_ADMIN_NEWBIE_ADJUST_REQUIRED = 'ValidationErrorNewbieAdjustRequired';
