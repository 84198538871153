import { IApiConResponse, IApiPostResponse, IApiResponse } from './api';
import { TNullable } from './common';
import { IWorkerHashrateData } from './hashrate';
export enum EWorkerStatus {
  INACTIVE = 'INACTIVE',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  DELETED = 'DELETED',
}

export type TWorkerFilterStatus = `${EWorkerStatus}`;

export interface INewWorkerData {
  name: string;
}

export type TWorkerId = number;
export type TWorkerGroupId = number;
export interface IWorker {
  id: TWorkerId;
  name: string;
  userId: number;
  hashRate10min: string;
  hashRate1hour: string;
  hashRate24h: string;
  rejected: string;
  lastActivity: string;
  status: EWorkerStatus;
  groupId: TWorkerGroupId | null;
}

export type TWorkerQuery = {
  page: string;
  size: string;
  sort?: string;
  status?: EWorkerStatus;
  filter?: string;
  order?: string;
};

export interface IWorkerGroup {
  id: number;
  name: string;
  userId: number;
  workersQuantity?: number;
  isDefault?: boolean;
  // TODO: consider workers quantity in fetch workers response and default flag
  // https://rutbot.jetbrains.space/p/thash/issue-boards/Frontend%20bugs?issues=THASH-T-36
}

export type TNormalizedGroups = {
  allIDs: IWorkerGroup['id'][];
  byID: { [id: IWorkerGroup['id']]: IWorkerGroup };
};

export interface IWorkerHashrate {}

export type TSortWorkersByHeaderTitle = keyof IWorker;

export interface IAddWorkerDataResponse extends IApiResponse {
  data: number;
}

export interface IWorkersResponse extends IApiResponse {
  data: { result: IWorker[]; page: number; size: number; count: number };
}

export interface IWorkersGroupsResponse extends IApiConResponse {
  data: IWorkerGroup[];
}

export interface IAddWorkersGroupResponse extends IApiPostResponse {
  data: IWorkerGroup;
}

export interface IDeleteWorkersGroupResponse extends IApiPostResponse {
  data: number;
}
export interface IAssignWorkerGroupToWorkerResponse extends IApiPostResponse {
  data: number;
}

export interface IEditWorkersGroupResponse extends IApiPostResponse {
  data: number;
}
export interface IWorkerHashrateResponse extends IApiResponse {
  data: IWorkerHashrateData[];
}

export interface IUserPoolUrlData {
  pool1: string;
  pool2: string;
  pool3: string;
  worker: string;
}
export interface IUSerPoolUrlReponse extends IApiResponse {
  data: IUserPoolUrlData[];
}

export interface IUserMinersStatisticsData {
  online: TNullable<number>;
  all: TNullable<number>;
  offline: TNullable<number>;
  inactive: TNullable<number>;
}

export interface IUserMinersStatisticsDataResponse extends IApiResponse {
  data: IUserMinersStatisticsData;
}

export interface IUserDeleteWorkerResponse extends IApiConResponse {}
export interface IUserRemoveWorkerFromGroupResponse extends IApiConResponse {}
export interface IUserMoveWorkerToGroupResponse extends IApiConResponse {}
