import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchAccrualsList } from 'store/thunks/userAccrualThunks';
import { IAccrual } from 'types/accruals';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IUserAccrualState {
  accruals: IAccrual[];
  status: ESliceDataFetchingStatus;
  pagesQuantity: number;
  itemsPerPage: number;
  currentPage: number;
  errorMessage: string | null;
  search: string | null;
  sortBy: string | null;
  sortDir: string | null;
  fromDate: string | null;
  toDate: string | null;
}

const initialState: IUserAccrualState = {
  accruals: [],
  status: ESliceDataFetchingStatus.initial,
  pagesQuantity: 1,
  itemsPerPage: 10,
  currentPage: 1,
  errorMessage: null,
  search: null,
  sortBy: null,
  sortDir: null,
  fromDate: null,
  toDate: null,
};

export const userAccrualsSlice = createSlice({
  name: 'userAccruals',
  initialState,
  reducers: {
    accrualsListSetPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setItemsPerAccrualsListPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = initialState.currentPage;
    },
    setSearch: (state, action: PayloadAction<IUserAccrualState['search']>) => {
      state.search = action.payload;
    },
    setSort: (
      state,
      action: PayloadAction<{
        sortBy: IUserAccrualState['sortBy'];
        sortDir: IUserAccrualState['sortDir'];
      }>,
    ) => {
      state.sortBy = action.payload.sortBy;
      state.sortDir = action.payload.sortDir;
    },
    setDateFilter: (
      state,
      action: PayloadAction<{
        fromDate: IUserAccrualState['fromDate'];
        toDate: IUserAccrualState['toDate'];
      }>,
    ) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchAccrualsList.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.accruals = payload.accruals;
        state.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchAccrualsList.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchAccrualsList.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch accruals list error';
      }),
});

export const selectAccruals = (state: RootState) => state.userAccruals;

export const selectCurentPage = createSelector(selectAccruals, (state) => {
  return state.currentPage;
});
export const selectPagesQuantity = createSelector(selectAccruals, (state) => {
  return state.pagesQuantity;
});
export const selectItemsPerPage = createSelector(selectAccruals, (state) => state.itemsPerPage);

export const selectPagesData = createSelector(selectAccruals, (state) => {
  const { currentPage, pagesQuantity } = state;
  return { currentPage, pagesQuantity };
});

export const {
  accrualsListSetPage,
  setItemsPerAccrualsListPage,
  setSearch,
  setSort,
  setDateFilter,
} = userAccrualsSlice.actions;

export const userAccrualsReducer = userAccrualsSlice.reducer;
