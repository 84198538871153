import styled from 'styled-components';

export const StyledCalendarContainer = styled.div`
  position: relative;

  width: fit-content;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};

  .calendar-back-layer {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 100000;
    transform: translateX(-50%);

    height: fit-content;

    padding-top: 5.5rem;

    @media screen and (min-width: 768px) {
      position: absolute;
      left: unset;
      transform: translateX(0);
    }
  }
`;

export const StyledCalenderInputGroup = styled.div`
  position: relative;
  width: 3.2rem;

  @media screen and (min-width: 768px) {
    width: 4.1rem;
  }

  @media screen and (min-width: 1024px) {
    width: 31.8rem;
  }

  .calendar-toggle-button {
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);

    width: 3rem;
    height: 3rem;

    border-radius: 5px;

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

    @media screen and (min-width: 768px) {
      width: 3.5rem;
      height: 3.5rem;
    }

    @media screen and (min-width: 1024px) {
      right: 0.5rem;
      transform: translate(0, -50%);
    }
    .calendar-icon {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);

      width: 2.5rem;
      height: 2.5rem;

      *[fill] {
        fill: currentColor;
      }

      @media screen and (min-width: 768px) {
        width: 3rem;
        height: 3rem;
      }
    }
  }
`;

export const StyledDateRangeInput = styled.input`
  width: 3.2rem;
  height: 3.2rem;

  padding: 1rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent1)};
  border-radius: 5px;

  font-size: 1.6rem;
  font-weight: 500;
  line-height: 150%; /* 27px */
  color: inherit;

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};
  outline: none;

  @media screen and (min-width: 768px) {
    width: 4.1rem;
    height: 4.1rem;
  }

  @media screen and (min-width: 1024px) {
    width: 100%;

    padding: 5px 5px 6px 8px;
    padding-right: 6.5rem;
  }

  &:focus,
  &.active {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

    border-radius: 5px;
    border: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};
  }
  &:focus ~ *,
  &.active ~ * {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};
  }
`;

export const StyledCustomCalendar = styled.div`
  position: relative;

  width: 32rem;

  padding: 5.5rem 4.8rem 4rem;

  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  border-radius: 5px;

  color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

  background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};
  box-shadow: ${(props) =>
    props.theme.id === 'dark' ? 'none' : '0px 23px 20px 0px rgba(173, 167, 206, 0.82)'};

  @media screen and (min-width: 768px) {
    width: 41rem;
  }

  .close-button {
    position: absolute;
    top: 1rem;
    right: 1.7rem;

    width: 3rem;
    height: 3rem;

    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.button2)};

    > * {
      fill: currentColor;
      width: 100%;
      height: 100%;
    }
  }

  & > *:first-child {
    margin-bottom: 4rem;
  }

  .periodic {
    display: block;

    font-size: 1.8rem;
    font-weight: 700;
    line-height: 150%;
    color: ${(props) => props.theme.text1};

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
    }
  }
  .react-calendar__month-view__days__day {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 4.5rem;
    height: 4.5rem;

    font-size: 1.8rem;
    font-weight: 700;
    line-height: 150%;

    &::before {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};
      opacity: 0;
      border-radius: 500px;
    }

    .abbr {
      position: relative;
      z-index: 1;
    }
    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text2)};
      &::before {
        opacity: 1;
      }
    }
  }

  .react-calendar__month-view__weekdays {
    margin-bottom: 1.3rem;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};
  }
  .react-calendar__month-view__weekdays__weekday {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent3 : props.theme.text4)};

    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */

    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.5rem;
    height: 4.5rem;
    padding: 0.2rem 1rem;
    flex-shrink: 0;
    > abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    font-size: 1.8rem;
  }
  .react-calendar__year-view__months__month,
  .react-calendar__decade-view__years__year {
    padding: 1rem;
    height: 4.5rem;
  }

  .react-calendar__decade-view__years {
    display: flex;
    justify-content: center;
  }

  .react-calendar__navigation {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.text2)};

    display: flex;
    justify-content: center;
    margin-bottom: 0.8rem;
  }

  .react-calendar__navigation__label {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
  }
  .react-calendar__navigation__arrow {
    width: 4rem;
    height: 4rem;
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent1)};

    > * {
      width: 100%;
      height: 100%;
      display: block;
      rect[fill] {
        fill: currentColor;
        stroke: currentColor;
      }

      path[fill] {
        fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text1 : props.theme.accent3)};
      }
    }

    &:hover {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5)};
      > * {
        rect[stroke] {
          stroke: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.text4)};
        }
        path[fill] {
          fill: ${(props) => (props.theme.id === 'dark' ? props.theme.button2 : props.theme.text4)};
        }
      }
    }
  }
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__prev2-button {
    display: none;
  }

  .react-calendar__tile--active {
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent4};
    &.react-calendar__month-view__days__day--neighboringMonth {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.button3 : props.theme.text5)};
    }
  }
  .react-calendar__tile--range {
  }
  .react-calendar__tile--rangeStart {
    border-top-left-radius: 500px;
    border-bottom-left-radius: 500px;
    &::before {
      opacity: 1;
    }
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text2)};
  }

  .react-calendar__tile--range {
  }
  .react-calendar__tile--rangeEnd {
    border-top-right-radius: 500px;
    border-bottom-right-radius: 500px;
    &::before {
      opacity: 1;
    }
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text2)};
  }
`;
