import { useTranslation } from 'react-i18next';
import { StyledWorkersListHeader } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { ReactComponent as CheckBoxIcon } from 'assets/icons/check-box-checked.svg';
import { TSortWorkersByHeaderTitle } from 'types';
import { useId } from 'react';
interface IWorkersListHeaderProps {
  children?: React.ReactNode;
  onSort: (sortBy: TSortWorkersByHeaderTitle) => void;
  onSelectAll: (selected: boolean) => void;
  selectedAll?: boolean;
  sortBy: string | null;
  sortDir: 'ASC' | 'DESC' | 'NONE';
}
export const WorkersListHeader = ({
  onSort,
  onSelectAll,
  selectedAll,
  sortBy,
  sortDir,
}: IWorkersListHeaderProps) => {
  const { t } = useTranslation();
  const selectAllChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    console.log(event.target.checked);
    onSelectAll(event.target.checked);
  };
  const selectAllInputId = useId();

  return (
    <StyledWorkersListHeader>
      <div className="workers-list-header-element select-all">
        <input
          id={selectAllInputId}
          className="select-all-input"
          type="checkbox"
          onChange={selectAllChangeHandler}
          checked={selectedAll}
        />
        <label htmlFor={selectAllInputId} className="select-all-label">
          <CheckBoxIcon />
        </label>
      </div>
      <div className="workers-list-header-element title">
        {t('workersPage.workersList.header.name')}
        <button
          className="sort-by-button"
          onClick={() => onSort('name')}
          style={{
            opacity: sortBy === 'name' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${sortBy === 'name' && sortDir !== 'NONE' ? '1.2' : '1'}) rotateZ(${
              sortBy === 'name' && sortDir === 'ASC' ? '180deg' : '0deg'
            })`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element hashrate">
        <div className="hashrate-element-text">
          <div className="hashrate-element-text-main">
            {t('workersPage.workersList.header.hashreateMin.main')}{' '}
          </div>
          <div className="hashrate-element-text-sub">
            {t('workersPage.workersList.header.hashreateMin.sub')}
          </div>
        </div>
        <button
          className="sort-by-button"
          onClick={() => onSort('hashRate10min')}
          style={{
            opacity: sortBy === 'hashRate10min' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'hashRate10min' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'hashRate10min' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element hashrate">
        <div className="hashrate-element-text">
          <div className="hashrate-element-text-main">
            {t('workersPage.workersList.header.hashreateHour.main')}{' '}
          </div>
          <div className="hashrate-element-text-sub">
            {t('workersPage.workersList.header.hashreateHour.sub')}
          </div>
        </div>
        <button
          className="sort-by-button"
          onClick={() => onSort('hashRate1hour')}
          style={{
            opacity: sortBy === 'hashRate1hour' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'hashRate1hour' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'hashRate1hour' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element hashrate">
        <div className="hashrate-element-text">
          <div className="hashrate-element-text-main">
            {t('workersPage.workersList.header.hashreateDay.main')}{' '}
          </div>
          <div className="hashrate-element-text-sub">
            {t('workersPage.workersList.header.hashreateDay.sub')}
          </div>
        </div>
        <button
          className="sort-by-button"
          onClick={() => onSort('hashRate24h')}
          style={{
            opacity: sortBy === 'hashRate24h' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'hashRate24h' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'hashRate24h' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element reject">
        {t('workersPage.workersList.header.reject')}
        <button
          className="sort-by-button"
          onClick={() => onSort('rejected')}
          style={{
            opacity: sortBy === 'rejected' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'rejected' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'rejected' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element last-activity">
        {t('workersPage.workersList.header.lastActivity')}
        <button
          className="sort-by-button"
          onClick={() => onSort('lastActivity')}
          style={{
            opacity: sortBy === 'lastActivity' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'lastActivity' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'lastActivity' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element">
        {t('workersPage.workersList.header.status')}
        <button
          className="sort-by-button"
          onClick={() => onSort('status')}
          style={{
            opacity: sortBy === 'status' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${sortBy === 'status' && sortDir !== 'NONE' ? '1.2' : '1'}) rotateZ(${
              sortBy === 'status' && sortDir === 'ASC' ? '180deg' : '0deg'
            })`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element">
        {t('workersPage.workersList.header.groups')}
        <button
          className="sort-by-button"
          onClick={() => onSort('groupId')}
          style={{
            opacity: sortBy === 'groupId' && sortDir !== 'NONE' ? 1 : 0.6,
            transform: `scale(${
              sortBy === 'groupId' && sortDir !== 'NONE' ? '1.2' : '1'
            }) rotateZ(${sortBy === 'groupId' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
          }}
        >
          <ArrowDownIcon />
        </button>
      </div>
      <div className="workers-list-header-element actions">
        {t('workersPage.workersList.header.actions')}
      </div>
    </StyledWorkersListHeader>
  );
};
