import styled from 'styled-components';
export const StyledInputGroup = styled.div`
  position: relative;
  .new-group-label {
    color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};

    font-size: 1.6rem;
    font-weight: 400;
    line-height: 150%; /* 24px */
    display: block;
    margin-bottom: 0.5rem;
  }
`;

export const StyledSelectContainer = styled.div`
  position: relative;

  &.expanded {
    height: 30rem;
  }

  .open {
    z-index: 1;
  }
`;

export const StyledWorkerSelect = styled.div`
  position: relative;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};

  font-size: 1.8rem;
  font-weight: 700;
  line-height: 150%; /* 27px */
  padding: 1rem;
  height: 4.9rem;

  .select-dropdown-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);

    width: 2.4rem;
    height: 2.4rem;

    > * {
      fill: currentColor;
      width: 100%;
      height: 100%;
    }

    &.active {
      transform: translateY(-50%) scaleX(-1) scaleY(-1);
    }
  }
`;

export const StyledWorkerSelectDropdown = styled.div`
  position: absolute;
  top: 3.9rem;
  border-radius: 8px;
  border: 1px solid
    ${(props) => (props.theme.id === 'dark' ? props.theme.accent2 : props.theme.accent3)};
  background: ${(props) => (props.theme.id === 'dark' ? props.theme.bg3 : props.theme.bg2)};
  padding: 1rem;
  width: 100%;
  max-height: 25rem;
  overflow: auto;

  .worker-select-dropdown-element {
    cursor: pointer;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 150%; /* 27px */
    height: 2.4rem;
    padding-right: 2rem;
    &.clear {
      color: red;
    }

    &:hover {
      .worker-select-dropdown-element-label {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2)};
      }
    }

    &-label {
      display: flex;
      justify-content: space-between;
      color: inherit;
      cursor: pointer;
      height: 100%;

      > * {
        height: 100%;
        *[stroke] {
          stroke: currentColor;
        }
        *[fill] {
          fill: currentColor;
        }
      }
    }

    &-input {
      &:checked ~ {
        .worker-select-dropdown-element-label {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};

          > * {
            *[fill] {
              fill: currentColor;
            }
          }
        }
      }
    }
  }
`;
