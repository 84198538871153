import { memo, useEffect, useState } from 'react';
import { WorkersListElement } from './WorkersListElement';
import { WorkersListHeader } from './WorkersListHeader';
import { StyledWorkersList } from './styles';
import { IWorker, TSortWorkersByHeaderTitle } from 'types';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getNextSortDir } from 'utils';
export const WorkersListComponent = ({
  onSort,
}: {
  children?: React.ReactNode;
  onSort: (sortBy: { sortBy: keyof IWorker; sortDir: 'ASC' | 'DESC' | 'NONE' }) => void;
}) => {
  const [selectedWorkersIds, setSelectedWorkersIds] = useState<IWorker['id'][]>([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'ASC' | 'DESC' | 'NONE'>('NONE');
  const { t } = useTranslation();

  const { workers } = useSelector(selectWorkers);

  const sortHandler = (nextSortBy: TSortWorkersByHeaderTitle) => {
    const nextSortDir = getNextSortDir(sortBy, nextSortBy, sortDir);
    onSort({ sortBy: nextSortBy, sortDir: nextSortDir });
    setSortBy(nextSortBy);
    setSortDir(nextSortDir);
  };
  const selectAllHandler = (isSelectedAll: boolean) => {
    if (isSelectedAll) {
      const allIds = workers.map((el) => el.id);
      setSelectedWorkersIds(allIds);
      setSelectedAll(true);
    } else {
      setSelectedWorkersIds([]);
      setSelectedAll(false);
    }
  };

  const selectHanler = (id: IWorker['id'], selected: boolean) => {
    if (selected) {
      setSelectedWorkersIds((prev) => [...prev, id]);
    } else {
      const updatedSelectedElementsIds = selectedWorkersIds.filter(
        (selectedId) => selectedId !== id,
      );
      setSelectedWorkersIds(updatedSelectedElementsIds);
    }
  };

  useEffect(() => {
    if (selectedAll && selectedWorkersIds.length !== workers.length) setSelectedAll(false);
  }, [selectedAll, selectedWorkersIds]);

  return (
    <StyledWorkersList>
      <WorkersListHeader
        onSort={sortHandler}
        onSelectAll={selectAllHandler}
        selectedAll={selectedAll}
        sortBy={sortBy}
        sortDir={sortDir}
      ></WorkersListHeader>
      {workers.length === 0 && <div>{t('workersPage.workersList.empty')}</div>}
      {workers.map((worker) => (
        <WorkersListElement
          key={worker.id}
          {...worker}
          onSelect={selectHanler}
          selected={selectedWorkersIds.includes(worker.id)}
        />
      ))}
    </StyledWorkersList>
  );
};

export const WorkersList = memo(WorkersListComponent);
