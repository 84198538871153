import {
  CustomCalendar,
  DisplayItemsPerPageSelect,
  DownloadDataButton,
  Footer,
  Layout,
  Paginator,
  Search,
  ServicePageHero,
  UserDataLayoutContainer,
} from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PayoutList,
  PayoutListContainer,
  PayoutListControls,
  PayoutsListFilter,
} from './components';

import { IPayout } from 'types';

import { StyledContent } from './StyledContent';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { fetchPayoutsList, fetchExportPayouts } from 'store/thunks/userPayoutThunks';
import {
  payoutsListSetPage,
  setItemsPerPayoutsListPage,
  setSearch,
  setSort,
  setDateFilter,
} from 'store/slices/userPayoutSlice';
import { downloadCsvFile } from 'utils';

import { GET_WORKERS_TIMEOUT_MS } from 'constants/appConstants';

export const PayoutPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeWalletAddressHandler = () => {
    navigate(`${Routes.settingsPath()}?focus=walletaddress`);
  };

  const { pagesQuantity, currentPage, itemsPerPage } = useSelector(
    (state: RootState) => state.userPayouts,
  );

  const setSearchFilterHandler = (value: string) => {
    dispatch(setSearch(value === '' ? null : value));
    dispatch(fetchPayoutsList());
  };

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(payoutsListSetPage(nextPage));
    dispatch(fetchPayoutsList());
  };
  const changeItemsPerPageQuantityHAndler = (itemsQuantity: number) => {
    dispatch(setItemsPerPayoutsListPage(itemsQuantity));
    dispatch(fetchPayoutsList());
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    const fromDate = range.from.toISOString();
    const toDate = range.to.toISOString();
    dispatch(
      setDateFilter({
        fromDate: fromDate === toDate ? null : fromDate,
        toDate: fromDate === toDate ? null : toDate,
      }),
    );
    dispatch(fetchPayoutsList());
  };

  const setSortHandler = (sortBy: { sortBy: keyof IPayout; sortDir: 'ASC' | 'DESC' | 'NONE' }) => {
    dispatch(
      setSort({
        sortBy: sortBy.sortDir === 'NONE' ? null : sortBy.sortBy,
        sortDir: sortBy.sortDir === 'NONE' ? null : sortBy.sortDir,
      }),
    );
    dispatch(fetchPayoutsList());
  };

  const getPayoutsList = () => {
    dispatch(fetchPayoutsList());

    return setTimeout(() => {
      getPayoutsList();
    }, GET_WORKERS_TIMEOUT_MS);
  };

  useEffect(() => {
    const timeout = getPayoutsList();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const download = async () => {
    const data = await dispatch(fetchExportPayouts()).unwrap();
    downloadCsvFile(data.exportData, 'Payouts');
  };

  return (
    <Layout>
      <UserDataLayoutContainer pageName="payoutPage">
        <StyledContent>
          <ServicePageHero
            title={t('serviceHero.changeWalletButton')}
            onClickButton={changeWalletAddressHandler}
          ></ServicePageHero>
          <PayoutListContainer>
            <PayoutListControls>
              <DownloadDataButton onClick={download} />
              <CustomCalendar onChange={changeDateRangeHandler} />
              <PayoutsListFilter />
              <Search onChange={setSearchFilterHandler} />
            </PayoutListControls>
            <PayoutList onSort={setSortHandler} />
            {pagesQuantity > 0 && (
              <div className="pagination-container">
                <Paginator
                  onChange={changeCurrentPageHandler}
                  pageQuantity={pagesQuantity}
                  currentPage={currentPage}
                />
                <DisplayItemsPerPageSelect
                  onChange={changeItemsPerPageQuantityHAndler}
                  currentItemsPerPageQuantity={itemsPerPage}
                />
              </div>
            )}
          </PayoutListContainer>
          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
