import { Input } from 'components';
import { StyledDonats } from './styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import { UserRepository } from 'repositories/UserRepository';
import { ChangeEvent, useCallback } from 'react';
import { setDonateValue } from 'store/slices/userSettings';
import toast from 'react-hot-toast';
import { EApiResponseStatus } from 'types/api';

interface IDonatsProps {
  children?: React.ReactNode;
}

const INPUT_DEBOUNCE = 500;

export const Donats = ({ children }: IDonatsProps) => {
  const { donate } = useAppSelector((state) => state.userSettings);
  const { token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  let timerId: ReturnType<typeof setTimeout> | null = null;
  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = Number(e.target.value.replace('%', ''));
      dispatch(setDonateValue(value));
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(async () => {
        try {
          if (!token) throw Error('unauthorized');
          const res = await UserRepository.setDonateSettings({ value }, token);
          if (res.status != EApiResponseStatus.SUCCESS) throw Error(res.error);
          toast.success(t('settingsPage.donats.changeSucceful'));
        } catch (e) {
          if (e instanceof Error) {
            toast.error(e.message);
          }
        }
      }, INPUT_DEBOUNCE);
    },
    [donate],
  );

  const { t } = useTranslation();
  return (
    <StyledDonats>
      {<label className="donat-input-label">{t('settingsPage.donats.label')}</label>}
      <Input
        className="donat-input"
        placeholder={t('settingsPage.donats.placeholder')}
        value={donate + '%'}
        onChange={onChange}
      />
      <p className="donat-input-remark">{t('settingsPage.donats.remark')}</p>
    </StyledDonats>
  );
};
