import { AddWorkerDialog, Button, Modal } from 'components';
import { ReactComponent as RoundCrossIcon } from 'assets/icons/round-cross.svg';
import { StyledAddWorker } from './styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectWorkers } from 'store/slices/userWorkersSlice';
import { useAppDispatch } from 'store/store';
import { ESliceDataFetchingStatus } from 'types/common';
import { selectAuth } from 'store/slices/authSlice';
import { fetchUserPoolUrls } from 'store/thunks/userWorkersThunks';
export const AddWorker = () => {
  const dispatch = useAppDispatch();
  const { userPoolUrlData, userPoolUrlDataStatus } = useSelector(selectWorkers);

  const { token } = useSelector(selectAuth);

  const { t } = useTranslation();
  const [isMountModal, setIsMountModal] = useState(false);
  const [showAddWorkerDialog, setShowAddWorkerDialog] = useState(false);
  const addWorkerHandler = () => {
    setShowAddWorkerDialog(true);
  };
  const cloaseModalHandler = () => {
    setShowAddWorkerDialog(false);
  };

  useEffect(() => {
    if (showAddWorkerDialog) {
      setIsMountModal(true);
    }

    if (!showAddWorkerDialog) {
      setTimeout(() => setIsMountModal(false), 500);
    }
  }, [showAddWorkerDialog]);

  useEffect(() => {
    if (token && !userPoolUrlData && userPoolUrlDataStatus === ESliceDataFetchingStatus.initial) {
      dispatch(fetchUserPoolUrls(token));
    }
  }, []);

  return (
    <StyledAddWorker>
      <Modal show={isMountModal}>
        <AddWorkerDialog onClose={cloaseModalHandler} show={isMountModal} />
      </Modal>
      <Button
        onClick={addWorkerHandler}
        icon={<RoundCrossIcon />}
        title={t('serviceHero.addWorkerButton')}
        disabled={!userPoolUrlData}
      ></Button>
    </StyledAddWorker>
  );
};
