import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/authSlice';
import { commonDataReducer } from './slices/commonDataSlice';
import { userStatisticsReducer } from './slices/userStatisticsSlice';
import { userWorkersReducer } from './slices/userWorkersSlice';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { userPayoutsReducer } from './slices/userPayoutSlice';
import { userAccrualsReducer } from './slices/userAccrualSlice';
import { userReferralReducer } from './slices/userReferralSlice';
import { userSettingsReducer } from './slices/userSettings';
import { themeMiddleware } from './middleware/themeMiddleware';
import { adminPaymentsReducer } from './slices/adminPaymentSlice';
import { adminCostsReducer } from './slices/adminCostSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    commonData: commonDataReducer,
    userStatistics: userStatisticsReducer,
    userWorkers: userWorkersReducer,
    userPayouts: userPayoutsReducer,
    userAccruals: userAccrualsReducer,
    userReferrals: userReferralReducer,
    userSettings: userSettingsReducer,
    adminPayments: adminPaymentsReducer,
    adminCosts: adminCostsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(themeMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
