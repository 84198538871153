import { useState } from 'react';
import { useTheme } from 'styled-components';
import {
  StyledAccuralDiagram,
  StyledAccuralChartElement,
  StyledHoverUpGroup,
  StyledTooltipGroup,
} from './styles';
import { useSelector } from 'react-redux';
import { selectAccruals } from 'store/slices/userAccrualSlice';
import { IAccrual, TPoint } from 'types/accruals';
import { ESliceDataFetchingStatus, TNullable } from 'types/common';
import { getFormatedDate } from 'utils/helpers';
import * as d3 from 'd3';
import cn from 'classnames';
interface IAccrualDiagramProps {
  children?: React.ReactNode;
  containerWidth: number;
}

export const AccuralDiagram = ({ containerWidth }: IAccrualDiagramProps) => {
  const userAccrualStatistics = useSelector(selectAccruals);

  const getDaysCount = (width: number) => {
    if (width >= 1415) return 12;
    if (width >= 1031) return 11;
    if (width >= 579) return 6;
    return 5;
  };

  const getCombinedAccruals = (accruals: TNullable<IAccrual[]>, width: number = 768) => {
    if (!accruals) return { accrualsPeriods: [], accruals: [] };

    const daysCount = getDaysCount(width);
    const accrualsPeriods: string[] = [];

    const now = new Date();
    now.setHours(0, 0, 0, 0);

    const result: { period: string; accruals: IAccrual[] }[] = [];

    for (let i = 0; i < daysCount; i++) {
      const periodDate = new Date(now);
      periodDate.setDate(now.getDate() - daysCount + i + 1);

      const periodString = getFormatedDate(periodDate);
      accrualsPeriods.push(periodString);

      const filteredAccruals = accruals.filter((acc) => {
        const [year, month, day] = acc.date.split('-');
        const date = `${day}.${month}.${year.slice(2)}`;
        return date === periodString;
      });

      result.push({ period: periodString, accruals: filteredAccruals });
    }

    return { accrualsPeriods, accruals: result };
  };

  const accrualData = getCombinedAccruals(userAccrualStatistics.accruals, containerWidth);

  return (
    <StyledAccuralDiagram>
      {userAccrualStatistics.status === ESliceDataFetchingStatus.initial && 'Loading...'}
      {userAccrualStatistics.status === ESliceDataFetchingStatus.loading &&
        userAccrualStatistics.accruals === null &&
        'Loading...'}
      {userAccrualStatistics.status === ESliceDataFetchingStatus.error && (
        <div className="data-error">Data is unavailable</div>
      )}
      {userAccrualStatistics.accruals !== null && (
        <RenderChart width={containerWidth} slicedRates={accrualData} height={250} />
      )}
    </StyledAccuralDiagram>
  );
};

export const RenderChart = ({
  width,
  height,
  slicedRates,
}: {
  width: number;
  height: number;
  slicedRates: {
    accrualsPeriods: string[];
    accruals: {
      period: string;
      accruals: IAccrual[];
    }[];
  };
}) => {
  const theme = useTheme();
  const [hoveredPeriod, setHoveredPeriod] = useState<number | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<number | null>(null);

  const axisYUnitsFieldWidth = 50;
  const dataFiledPaddingTop = 13;
  const axisXUnitsFieldHeight = (height - axisYUnitsFieldWidth - dataFiledPaddingTop) / 4;

  const tooltipConfig: {
    width: number;
    height: number;
    marginTop: number;
    marginLeft: number;
    paddingLeft: number;
    paddingTop: number;
    lineHieght: number;
  } = {
    width: 80,
    height: 84,
    marginTop: 10,
    marginLeft: 10,
    paddingLeft: 5,
    paddingTop: 10,
    lineHieght: 22,
  };

  const unitXdataBlockConfig: { width: number; height: number } = { width: 68, height: 40 };

  const accrualSums = slicedRates.accruals.map(({ accruals }) =>
    accruals.reduce((sum, { amount }) => sum + amount, 0),
  );

  const accrualPoints = accrualSums.map<[number, number]>((sum, i) => [i + 1, sum]);

  accrualPoints.unshift([0, accrualSums[0] || 0]);
  accrualPoints.push([accrualPoints.length, accrualSums[accrualSums.length - 1] || 0]);

  const accrualMaxY = Math.max(...accrualSums, 0.05);
  const accrualMaxX = accrualPoints.length;

  const yTicks = [accrualMaxY, accrualMaxY / 1.3, accrualMaxY / 2, accrualMaxY / 4].map((tick) =>
    tick.toFixed(8),
  );
  yTicks.push('0');

  const x = (width - axisYUnitsFieldWidth * 2) / (accrualMaxX - 2) / 2;

  const periodsXScaler = d3.scaleLinear(
    [0, accrualMaxX - 1],
    [axisYUnitsFieldWidth + x * 0.25, width - axisYUnitsFieldWidth + x],
  );

  const accrualsYScaler = d3.scaleLinear(
    [accrualMaxY, 0],
    [dataFiledPaddingTop, axisXUnitsFieldHeight * 4 + dataFiledPaddingTop],
  );

  const buildLine = (data: [number, number][], yScaler: d3.ScaleLinear<number, number, never>) => {
    const lineBuilder = d3
      .line()
      .x((d) => periodsXScaler(d[0]))
      .y((d) => yScaler(d[1]))
      .curve(d3.curveMonotoneX);

    return lineBuilder(data);
  };

  const axisYUnitsSublinesCount = 5;
  const accrualsLine = buildLine(accrualPoints, accrualsYScaler);

  const accrualYUnits = Array.from(Array(axisYUnitsSublinesCount).keys()).map(
    (_, i) => accrualMaxY - Math.round((i * accrualMaxY) / (axisYUnitsSublinesCount - 1)),
  );

  const onChartFiledMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const periodOverMouse = Math.round(periodsXScaler.invert(mouseX + axisYUnitsFieldWidth));
    setHoveredPeriod(periodOverMouse);
  };
  const onChartFiledClick = (event: React.MouseEvent<SVGRectElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const mouseX = event.clientX - rect.left;
    const periodOverMouse = Math.round(periodsXScaler.invert(mouseX + axisYUnitsFieldWidth));
    setSelectedPeriod((prev) => (periodOverMouse === prev ? null : periodOverMouse));
  };

  const onHoverPeriod = (period: number | null) => {
    setHoveredPeriod(period);
  };

  const onMouseLeaveChart = () => {
    setHoveredPeriod(null);
    setSelectedPeriod(null);
  };

  return (
    <StyledAccuralChartElement>
      <svg
        className="svg-accurals-chart"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        onMouseLeave={onMouseLeaveChart}
      >
        <defs>
          <linearGradient id="fillGradient" x1="0%" y1="0%" x2="0%" y2="80%">
            <stop
              offset="0%"
              stopColor={theme.id === 'dark' ? theme.accent1 : theme.bg7}
              stopOpacity={theme.id === 'dark' ? '0.69' : '1'}
            />
            <stop
              offset="100%"
              stopColor={theme.id === 'dark' ? theme.bg1 : theme.bg7}
              stopOpacity={theme.id === 'dark' ? '0.24' : '0'}
            />
          </linearGradient>

          <filter id="glow">
            <feGaussianBlur stdDeviation="3" result="coloredBlur" />
            <feMerge>
              <feMergeNode in="coloredBlur" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        <rect className="svg-accurals-chart-bg" width={width} height={height} />
        {accrualYUnits.map((d, i) => (
          <g key={d + i}>
            <path
              className="y-line"
              d={`M ${axisYUnitsFieldWidth},${i * axisXUnitsFieldHeight + dataFiledPaddingTop}
             H${width - axisYUnitsFieldWidth}`}
            ></path>
          </g>
        ))}

        <path className="data-line primary" d={accrualsLine!} filter="url(#glow)" />
        <path
          className="data-area"
          d={`${accrualsLine} L ${width},${height - 50} L 0,${height - 50} Z`}
          fill="url(#fillGradient)"
        />

        {hoveredPeriod && (
          <HoverUpGroup
            dimmed={!!selectedPeriod}
            start={{
              x: periodsXScaler(hoveredPeriod),
              y: accrualsYScaler(accrualPoints[hoveredPeriod][1]),
            }}
            lineEndY={200}
            diameter={{ primary: 7.5, secondary: 5 }}
          >
            {!selectedPeriod && (
              <TooltipGroup
                containerWidth={width}
                axisYUnitsFieldWidth={axisYUnitsFieldWidth}
                start={{
                  x: periodsXScaler(hoveredPeriod),
                  y: accrualsYScaler(accrualPoints[hoveredPeriod][1]),
                }}
                config={tooltipConfig}
                periodValue={slicedRates.accrualsPeriods[hoveredPeriod - 1]}
                accrualsValue={`${accrualPoints[hoveredPeriod][1].toFixed(8)} BTC`}
              />
            )}
          </HoverUpGroup>
        )}

        {selectedPeriod && (
          <g className="tooltip-element">
            <HoverUpGroup
              start={{
                x: periodsXScaler(selectedPeriod),
                y: accrualsYScaler(accrualPoints[selectedPeriod][1]),
              }}
              lineEndY={210}
              diameter={{ primary: 7.5, secondary: 5 }}
            >
              <TooltipGroup
                containerWidth={width}
                axisYUnitsFieldWidth={axisYUnitsFieldWidth}
                start={{
                  x: periodsXScaler(selectedPeriod),
                  y: accrualsYScaler(accrualPoints[selectedPeriod][1]),
                }}
                config={tooltipConfig}
                periodValue={slicedRates.accrualsPeriods[selectedPeriod - 1]}
                accrualsValue={`${accrualPoints[selectedPeriod][1].toFixed(8)} BTC`}
              />
            </HoverUpGroup>
          </g>
        )}

        <rect
          className="svg-accurals-chart-bg"
          x={0}
          width={axisYUnitsFieldWidth * 2.25}
          height={height}
        />
        <rect
          className="svg-accurals-chart-bg"
          x={width - axisYUnitsFieldWidth + 10}
          width={axisYUnitsFieldWidth}
          height={height}
        />

        {yTicks.map((d, i) => (
          <g key={d + i} className="y-unit">
            <text className="y-unit-text" x={0} y={i * axisXUnitsFieldHeight + dataFiledPaddingTop}>
              {d}
            </text>
          </g>
        ))}

        {slicedRates.accrualsPeriods.map((d, i) => (
          <g
            className="x-unit"
            key={d + i}
            onClick={() => setSelectedPeriod(i + 1)}
            onMouseMove={() => onHoverPeriod(i + 1)}
          >
            <rect
              className={cn(
                'x-unit-bg',
                selectedPeriod && selectedPeriod !== i + 1 && 'other-selected',
              )}
              x={periodsXScaler(i + 1) - unitXdataBlockConfig.width / 2}
              y={height - unitXdataBlockConfig.height}
              width={unitXdataBlockConfig.width}
              height={unitXdataBlockConfig.height}
              rx={5}
              visibility={
                hoveredPeriod === i + 1 || selectedPeriod === i + 1 ? 'visible' : 'hidden'
              }
              pointerEvents={'all'}
            ></rect>
            <text
              className="x-unit-text"
              dominantBaseline="middle"
              x={periodsXScaler(i + 1)}
              y={height - unitXdataBlockConfig.height / 2}
            >
              {d}
            </text>
          </g>
        ))}

        <rect
          className="data-feild"
          y={dataFiledPaddingTop}
          x={axisYUnitsFieldWidth}
          width={width - axisYUnitsFieldWidth * 2}
          height={height - axisXUnitsFieldHeight - dataFiledPaddingTop}
          fill="cyan"
          onMouseMove={onChartFiledMouseMove}
          onClick={onChartFiledClick}
          visibility={'hidden'}
          pointerEvents={'all'}
        />
      </svg>
    </StyledAccuralChartElement>
  );
};

interface IHoverUpGroupProps {
  children: React.ReactNode;
  start: TPoint;
  lineEndY: number;
  dimmed?: boolean;
  diameter: { primary: number; secondary: number };
}

const HoverUpGroup = ({ start, children, dimmed, lineEndY, diameter }: IHoverUpGroupProps) => {
  const { x, y } = start;

  const { primary, secondary } = diameter;
  return (
    <StyledHoverUpGroup className={cn(dimmed && 'dimmed')}>
      <path className="data-point-line" d={`M ${x},${y} ${x},${lineEndY}`} />
      <circle className="data-point primary" cx={x} cy={y} r={primary} />
      {children}
    </StyledHoverUpGroup>
  );
};

interface ITooltipGroupProps {
  containerWidth: number;
  axisYUnitsFieldWidth: number;
  start: TPoint;
  config: {
    width: number;
    height: number;
    marginTop: number;
    marginLeft: number;
    paddingLeft: number;
    paddingTop: number;
    lineHieght: number;
  };
  periodValue: number | string;
  accrualsValue: number | string;
}

const TooltipGroup = ({
  config,
  start,
  containerWidth,
  axisYUnitsFieldWidth,
  periodValue,
  accrualsValue,
}: ITooltipGroupProps) => {
  const { width, height, marginTop, marginLeft, paddingLeft, paddingTop, lineHieght } = config;

  const getTooltipY = (startY: number, tooltipHeight: number, marginTop: number) => {
    const offsetTop = startY > tooltipHeight ? tooltipHeight : 0;
    return startY - marginTop - offsetTop;
  };

  const getTooltipX = (
    startX: number,
    tooltipWidth: number,
    marginLeft: number,
    containerWidth: number,
    axisYUnitsFieldWidth: number,
  ) => {
    const offsetRight =
      containerWidth - axisYUnitsFieldWidth - startX < tooltipWidth
        ? -tooltipWidth - marginLeft
        : marginLeft;
    return startX + offsetRight;
  };

  const tooltipX = getTooltipX(start.x, width, marginLeft, containerWidth, axisYUnitsFieldWidth);
  const tooltipY = getTooltipY(start.y, height, marginTop);

  const periodPos: TPoint = {
    x: tooltipX + paddingLeft,
    y: tooltipY + marginTop + paddingTop,
  };

  const accrualsPos: TPoint = {
    x: periodPos.x,
    y: periodPos.y + lineHieght,
  };

  return (
    <StyledTooltipGroup className="tooltip-group">
      <rect className="tooltip-group-bg" width={150} height={65} x={tooltipX} y={tooltipY} rx={5} />
      <text className="tooltip-group-text" {...periodPos}>
        {periodValue}
      </text>
      <text className="tooltip-group-text hashrate" {...accrualsPos}>
        {accrualsValue}
      </text>
    </StyledTooltipGroup>
  );
};
