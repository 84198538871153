import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserWorkersState } from 'store/slices/userWorkersSlice';
import { IUSerPoolUrlReponse, IWorker, IWorkerGroup, TWorkerQuery } from 'types';
import { EApiResponseStatus } from 'types/api';

export const fetchWorkersList = createAsyncThunk<
  {
    workers: IWorker[];
    count: number;
    size: number;
  },
  void,
  { state: { auth: IAuthState; userWorkers: IUserWorkersState } }
>('userWorkers/fetchWorkersList', async (_, { getState }) => {
  const {
    auth: { token },
    userWorkers: { currentPage, itemsPerPage, filterByActivityStatus, search, sortBy, sortDir },
  } = getState();

  if (!token) throw Error('no token');

  const params: TWorkerQuery = { page: String(currentPage - 1), size: String(itemsPerPage) };
  if (filterByActivityStatus) params.status = filterByActivityStatus;
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  const workersResponse = await UserRepository.getWorkers(token, params);

  if (workersResponse.status === 'failed') throw Error(workersResponse.error);

  return {
    workers: workersResponse.data.result,
    count: workersResponse.data.count,
    size: workersResponse.data.size,
  };
});

export const fetchWorkersGroupsList = createAsyncThunk<
  IWorkerGroup[],
  void,
  { state: { auth: IAuthState } }
>('userWorkers/fetchWorkersGroupsList', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');

  const workersGroupsResponse = await UserRepository.getWorkersGroups(token);

  if (workersGroupsResponse.status === EApiResponseStatus.FAILURE)
    throw Error(workersGroupsResponse.error);

  return workersGroupsResponse.data;
});

export const fetchUserPoolUrls = createAsyncThunk<IUSerPoolUrlReponse, string>(
  'userWorkers/fetchUserPoolUrls',
  async (token) => {
    const userPoolLinks = await UserRepository.getUserPoolLinks(token);
    if (!Array.isArray(userPoolLinks.data))
      throw Error('wrong data format, expected array: ' + JSON.stringify(userPoolLinks.data));
    if (!userPoolLinks.data[0].pool1)
      throw Error('wrong data format, no pool url' + JSON.stringify(userPoolLinks.data));
    return userPoolLinks;
  },
);
