import { apiAdminRoutes } from 'lib';
import { apiClient } from 'lib/ApiClient';
import {
  IPaymentsCalculateResponse,
  IServerDateTimeResponse,
  TPaymentsCalculateQuery,
} from 'types';
import {
  ICostsResponse,
  INewbieAdjustResponse,
  INewbieCostResponse,
  IYesterdayCostResponse,
} from 'types/costs';

export const AdminRepository = {
  getPaymentsCalculate: async (token: string, params: TPaymentsCalculateQuery) => {
    const url = apiAdminRoutes.apiAdminPaymentsCalculate();
    const response = apiClient.post(
      `${url}`,
      {},
      {
        queryParams: {
          date: params.date,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response as Promise<IPaymentsCalculateResponse>;
  },
  getServerDateTime: async (token: string) => {
    const url = apiAdminRoutes.apiFetchServerDateTime();
    const response = apiClient.get(`${url}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<IServerDateTimeResponse>;
  },
  getCosts: async (token: string) => {
    const url = apiAdminRoutes.apiAdminCosts();
    const response = apiClient.get(`${url}`, {
      Authorization: `Bearer ${token}`,
    });
    return response as Promise<ICostsResponse>;
  },
  setYesterdayCost: async (
    token: string,
    costData: {
      yesterdayCost: number;
      newbieCost: number;
      newbieAdjust: number;
    },
  ) => {
    const url = apiAdminRoutes.apiAdminYesterdayCost();
    const editYesterdayCostResponse = (await apiClient.post(url, costData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as IYesterdayCostResponse;
    return editYesterdayCostResponse;
  },
  setNewbieCost: async (token: string, costData: { newbieCost: number }) => {
    const url = apiAdminRoutes.apiAdminNewbieCost();
    const editNewbieCostResponse = (await apiClient.post(url, costData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as INewbieCostResponse;
    return editNewbieCostResponse;
  },
  setNewbieAdjust: async (
    token: string,
    adjustData: {
      newbieAdjust: number;
    },
  ) => {
    const url = apiAdminRoutes.apiAdminNewbieAdjust();
    const editNewbieAdjustResponse = (await apiClient.post(url, adjustData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })) as INewbieAdjustResponse;
    return editNewbieAdjustResponse;
  },
};
