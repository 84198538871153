import { useTranslation } from 'react-i18next';
import { StyledReferralUsersList } from './styles';
import {
  BorderedWrapper,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  Paginator,
  Search,
} from 'components';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { formateDate, formateTime } from 'utils';
import { TSortIReferralUserByHeaderTitle } from 'types';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { fetchReferralUsersList } from 'store/thunks/userReferralThunks';
import { useSelector } from 'react-redux';
import {
  selectReferralUsers,
  referralUsersListSetPage,
  setItemsPerReferralUsersListPage,
  setReferralUsersSearch,
  setReferralUsersSort,
  setReferralUsersDateFilter,
} from 'store/slices/userReferralSlice';
import { getNextSortDir } from 'utils';
interface IReferralUsersListProps {
  children?: React.ReactNode;
}
export const ReferralUsersList = ({ children }: IReferralUsersListProps) => {
  const { users, pagesQuantity, currentPage, itemsPerPage } = useSelector(selectReferralUsers);
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'ASC' | 'DESC' | 'NONE'>('NONE');

  const dispatch = useAppDispatch();

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(referralUsersListSetPage(nextPage));
    dispatch(fetchReferralUsersList());
  };

  const changeItemsPerPageQuantityHandler = (itemsQuantity: number) => {
    dispatch(setItemsPerReferralUsersListPage(itemsQuantity));
    dispatch(fetchReferralUsersList());
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    const fromDate = range.from.toISOString();
    const toDate = range.to.toISOString();
    dispatch(
      setReferralUsersDateFilter({
        fromDate: fromDate === toDate ? null : fromDate,
        toDate: fromDate === toDate ? null : toDate,
      }),
    );
    dispatch(fetchReferralUsersList());
  };

  const setSearchFilterHandler = (value: string) => {
    dispatch(setReferralUsersSearch(value === '' ? null : value));
    dispatch(fetchReferralUsersList());
  };

  const setSortHandler = (sortBy: {
    sortBy: TSortIReferralUserByHeaderTitle;
    sortDir: 'ASC' | 'DESC' | 'NONE';
  }) => {
    dispatch(
      setReferralUsersSort({
        sortBy: sortBy.sortDir === 'NONE' ? null : sortBy.sortBy,
        sortDir: sortBy.sortDir === 'NONE' ? null : sortBy.sortDir,
      }),
    );
    dispatch(fetchReferralUsersList());
  };

  const sortHandler = (nextSortBy: TSortIReferralUserByHeaderTitle) => {
    const nextSortDir = getNextSortDir(sortBy, nextSortBy, sortDir);
    setSortHandler({ sortBy: nextSortBy, sortDir: nextSortDir });
    setSortBy(nextSortBy);
    setSortDir(nextSortDir);
  };

  useEffect(() => {
    dispatch(fetchReferralUsersList());
  }, []);

  return (
    <StyledReferralUsersList>
      <BorderedWrapper>
        <div className="referral-list-controls-container">
          <h2 className="referral-list-heading">{t('referralsPage.referralUsersList.heading')}</h2>
          <div className="referral-list-controls">
            <CustomCalendar onChange={changeDateRangeHandler} />
            <Search onChange={setSearchFilterHandler} />
          </div>
        </div>
        <div className="referral-list-container">
          <div className="referral-list-header">
            <div className="referral-list-header-element email">
              <div>{t('referralsPage.referralUsersList.header.email')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('email')}
                style={{
                  opacity: sortBy === 'email' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'email' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${sortBy === 'email' && sortDir === 'ASC' ? '180deg' : '0deg'})`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element current-hashrate">
              <div>{t('referralsPage.referralUsersList.header.currentHashrate')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('currnetHashRate')}
                style={{
                  opacity: sortBy === 'currnetHashRate' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'currnetHashRate' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${
                    sortBy === 'currnetHashRate' && sortDir === 'ASC' ? '180deg' : '0deg'
                  })`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element monthly-hashrate">
              <div>{t('referralsPage.referralUsersList.header.monthlyHashrate')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('monthlyHashRate')}
                style={{
                  opacity: sortBy === 'monthlyHashRate' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'monthlyHashRate' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${
                    sortBy === 'monthlyHashRate' && sortDir === 'ASC' ? '180deg' : '0deg'
                  })`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
            <div className="referral-list-header-element date">
              <div>{t('referralsPage.referralUsersList.header.registrationDate')}</div>
              <button
                className="sort-by-button"
                onClick={() => sortHandler('registrationDate')}
                style={{
                  opacity: sortBy === 'registrationDate' && sortDir !== 'NONE' ? 1 : 0.6,
                  transform: `scale(${
                    sortBy === 'registrationDate' && sortDir !== 'NONE' ? '1.2' : '1'
                  }) rotateZ(${
                    sortBy === 'registrationDate' && sortDir === 'ASC' ? '180deg' : '0deg'
                  })`,
                }}
              >
                <ArrowDownIcon className="sort-icon" />
              </button>
            </div>
          </div>
          <div className="referral-list-body">
            {users.map((user) => {
              const elementdate = new Date(user.registrationDate);
              return (
                <div key={user.id} className="referral-list-row">
                  <div className="referral-list-element email">{user.email}</div>
                  <div className="referral-list-element current-hashrate">
                    {user.currnetHashRate}
                  </div>
                  <div className="referral-list-element monthly-hashrate">
                    {user.monthlyHashRate}
                  </div>
                  <div className="referral-list-element date">
                    <span>{formateDate(elementdate)}</span>
                    <span className="time">
                      &nbsp;-&nbsp;
                      {`${formateTime(elementdate)}`}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          {pagesQuantity > 0 && (
            <div className="pagination-container">
              <Paginator
                currentPage={currentPage}
                pageQuantity={pagesQuantity}
                onChange={changeCurrentPageHandler}
              />
              <DisplayItemsPerPageSelect
                onChange={changeItemsPerPageQuantityHandler}
                currentItemsPerPageQuantity={itemsPerPage}
              />
            </div>
          )}
        </div>
      </BorderedWrapper>
    </StyledReferralUsersList>
  );
};
