import { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-cross.svg';
import cn from 'classnames';
import {
  StyledCloseButton,
  StyledReferralLevelsDialog,
  StyledReferralLevelsDialogContainer,
  StyledReferralLevelsDialogOverlay,
} from './styles';
import { useTranslation } from 'react-i18next';

interface IReferralLevelsDialogProps {
  children?: React.ReactNode;
  onClose: () => void;
  show?: boolean;
}
export const ReferralLevelsDialog = ({ onClose, show }: IReferralLevelsDialogProps) => {
  const { t } = useTranslation();
  const [showContent, setShowContent] = useState(false);

  const closeHandler = () => {
    onClose();
    setShowContent(false);
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => setShowContent(() => true), 100);
    }
  }, [show]);
  return (
    <StyledReferralLevelsDialogContainer>
      <StyledReferralLevelsDialogOverlay
        onClick={closeHandler}
        className={cn(showContent && 'show')}
      />
      {showContent && (
        <StyledReferralLevelsDialog open={showContent}>
          <StyledCloseButton onClick={closeHandler}>
            <CloseIcon />
          </StyledCloseButton>
          <div className="levels-dialog-heading">
            {t('referralsPage.levels.levelsDialog.heading')}
          </div>
          <div className="levels-dialog-subheading">
            {t('referralsPage.levels.levelsDialog.subheading')}
          </div>
          <table>
            <thead>
              <tr>
                <th>{t('referralsPage.levels.levelsDialog.levelColumnHead')}</th>
                <th>{t('referralsPage.levels.levelsDialog.tnkColumnHead')}</th>
                <th>{t('referralsPage.levels.levelsDialog.percentColumnHead')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel1')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel1')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel1')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel2')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel2')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel2')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel3')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel3')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel3')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel4')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel4')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel4')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel5')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel5')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel5')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel6')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel6')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel6')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel7')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel7')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel7')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel8')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel8')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel8')}</td>
              </tr>
              <tr>
                <td>{t('referralsPage.levels.levelsDialog.levelColumnLevel9')}</td>
                <td>{t('referralsPage.levels.levelsDialog.tnkColumnLevel9')}</td>
                <td>{t('referralsPage.levels.levelsDialog.percentColumnLevel9')}</td>
              </tr>
            </tbody>
          </table>
        </StyledReferralLevelsDialog>
      )}
    </StyledReferralLevelsDialogContainer>
  );
};
