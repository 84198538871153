import styled from 'styled-components';
export const StyledWorkersList = styled.div`
  padding-bottom: 1.5rem;
  margin-bottom: 1.6rem;
  overflow: auto;
  scrollbar-color: #7456ca #7456ca;

  &::-webkit-scrollbar {
    width: 0;
    height: 15px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button2 : props.theme.accent4};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${(props) =>
      props.theme.id === 'dark' ? props.theme.button3 : props.theme.accent1};
  }

  @media screen and (min-width: 1920px) {
    padding-bottom: 0;
  }
`;
