import { Search } from 'components';
import { StyledWorkerControls } from './styles';
import { WorkersListFilters } from '../WorkersListFilters';
import { WorkerGroups } from '../WorkerGroups';
import { useAppDispatch } from 'store/store';
import { fetchWorkersList } from 'store/thunks/userWorkersThunks';
import { setSearch } from 'store/slices/userWorkersSlice';

export const WorkerControls = () => {
  const dispatch = useAppDispatch();

  const searchHandler = (value: string) => {
    dispatch(setSearch(value === '' ? null : value));
    dispatch(fetchWorkersList());
  };
  return (
    <StyledWorkerControls>
      <WorkerGroups />
      <WorkersListFilters />
      <Search onChange={searchHandler} />
    </StyledWorkerControls>
  );
};
