import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserPayoutState } from 'store/slices/userPayoutSlice';
import { IPayout, TPayoutQuery } from 'types';

export const fetchPayoutsList = createAsyncThunk<
  { payouts: IPayout[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userPayouts: IUserPayoutState } }
>('userPayouts/fetchPayoutsList', async (_, { getState }) => {
  const {
    auth: { token },
    userPayouts: {
      currentPage,
      itemsPerPage,
      filterByConfirmationStatus,
      search,
      sortBy,
      sortDir,
      fromDate,
      toDate,
    },
  } = getState();

  if (!token) throw Error('no token');

  const params: TPayoutQuery = { page: String(currentPage - 1), size: String(itemsPerPage) };
  if (filterByConfirmationStatus) params.status = filterByConfirmationStatus;
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  if (fromDate) params.from = fromDate;
  if (toDate) params.to = toDate;
  const payoutsListResponse = await UserRepository.getPayouts(token, params);

  if (payoutsListResponse.status === 'failed') throw Error(payoutsListResponse.error);

  return {
    payouts: payoutsListResponse.data.result,
    count: payoutsListResponse.data.count,
    size: payoutsListResponse.data.size,
  };
});

export const fetchExportPayouts = createAsyncThunk<
  { exportData: string },
  void,
  { state: { auth: IAuthState } }
>('userAccruals/fetchExportPayouts', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();

  if (!token) throw Error('no token');

  const exportPayoutsResponse = await UserRepository.getExportPayouts(token);

  if (exportPayoutsResponse.status === 'failed') throw Error(exportPayoutsResponse.error);

  return {
    exportData: exportPayoutsResponse.data,
  };
});
