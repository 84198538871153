import { createAsyncThunk } from '@reduxjs/toolkit';
import { AdminRepository } from 'repositories/AdminRepository';
import { IAdminPaymentState } from 'store/slices/adminPaymentSlice';
import { IAuthState } from 'store/slices/authSlice';
import { TPaymentsCalculateQuery } from 'types';
import { EApiResponseStatus } from 'types/api';
import { getFormattedServerDateTimeRequest } from 'utils';

export const fetchPaymentsCalculate = createAsyncThunk<
  object,
  void,
  { state: { auth: IAuthState; adminPayments: IAdminPaymentState } }
>('adminPayments/fetchPaymentsCalculate', async (_, { getState }) => {
  const {
    auth: { token },
    adminPayments: { serverDateTime },
  } = getState();
  if (!token) throw Error('no token');
  if (!serverDateTime) throw Error('no server date');

  const params: TPaymentsCalculateQuery = {
    date: getFormattedServerDateTimeRequest(serverDateTime),
  };
  const paymentsCalculateResponse = await AdminRepository.getPaymentsCalculate(token, params);

  if (paymentsCalculateResponse.status === EApiResponseStatus.FAILURE) {
    throw Error(paymentsCalculateResponse.error);
  }

  return paymentsCalculateResponse.data;
});

export const fetchServerDateTime = createAsyncThunk<
  { time: string },
  void,
  { state: { auth: IAuthState } }
>('adminPayments/fetchPaymentsCalculate', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();
  if (!token) throw Error('no token');

  const paymentsCalculateResponse = await AdminRepository.getServerDateTime(token);

  if (paymentsCalculateResponse.status === EApiResponseStatus.FAILURE) {
    throw Error(paymentsCalculateResponse.error);
  }

  return paymentsCalculateResponse.data;
});
