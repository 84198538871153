import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserAccrualState } from 'store/slices/userAccrualSlice';
import { IAccrual, TAccrualsQuery } from 'types/accruals';

export const fetchAccrualsList = createAsyncThunk<
  { accruals: IAccrual[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userAccruals: IUserAccrualState } }
>('userAccruals/fetchAccrualsList', async (_, { getState }) => {
  const {
    auth: { token },
    userAccruals: { currentPage, itemsPerPage, search, sortBy, sortDir, fromDate, toDate },
  } = getState();

  if (!token) throw Error('no token');

  const params: TAccrualsQuery = { page: String(currentPage - 1), size: String(itemsPerPage) };
  if (search) params.filter = search;
  if (sortBy) params.sort = sortBy;
  if (sortDir) params.order = sortDir;
  if (fromDate) params.from = fromDate;
  if (toDate) params.to = toDate;
  const accrualsListResponse = await UserRepository.getAccruals(token, params);

  if (accrualsListResponse.status === 'failed') throw Error(accrualsListResponse.error);

  return {
    accruals: accrualsListResponse.data.result,
    count: accrualsListResponse.data.count,
    size: accrualsListResponse.data.size,
  };
});

export const fetchExportAccruals = createAsyncThunk<
  { exportData: string },
  void,
  { state: { auth: IAuthState } }
>('userAccruals/fetchExportAccruals', async (_, { getState }) => {
  const {
    auth: { token },
  } = getState();

  if (!token) throw Error('no token');

  const exportAccrualsResponse = await UserRepository.getExportAccruals(token);

  if (exportAccrualsResponse.status === 'failed') throw Error(exportAccrualsResponse.error);

  return {
    exportData: exportAccrualsResponse.data,
  };
});
