import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AccuralSchedule,
  CustomCalendar,
  DisplayItemsPerPageSelect,
  DownloadDataButton,
  Footer,
  Layout,
  Paginator,
  Search,
  ServicePageHero,
  UserDataLayoutContainer,
} from 'components';
import { ChargeList, ChargesListContainer, ChargesListControls } from './components';
import { ICharge } from 'types';
import { StyledContent } from './StyledContent';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'lib';
import { RootState, useAppDispatch } from 'store/store';
import { useSelector } from 'react-redux';
import {
  selectCurentPage,
  selectPagesQuantity,
  accrualsListSetPage,
  selectItemsPerPage,
  setItemsPerAccrualsListPage,
  setSearch,
  setSort,
  setDateFilter,
} from 'store/slices/userAccrualSlice';
import { fetchAccrualsList, fetchExportAccruals } from 'store/thunks/userAccrualThunks';
import { downloadCsvFile } from 'utils';
import { GET_WORKERS_TIMEOUT_MS } from 'constants/appConstants';

export const ProfitPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const pagesQuantity = useSelector((state: RootState) => selectPagesQuantity(state));
  const currentPage = useSelector((state: RootState) => selectCurentPage(state));
  const itemsPerPage = useSelector((state: RootState) => selectItemsPerPage(state));

  const changeWalletButton = () => {
    navigate(`${Routes.settingsPath()}?focus=walletaddress`);
  };

  const changeCurrentPageHandler = (nextPage: number) => {
    dispatch(accrualsListSetPage(nextPage));
    dispatch(fetchAccrualsList());
  };

  const changeItemsPerPageQuantityHandler = (itemsQuantity: number) => {
    dispatch(setItemsPerAccrualsListPage(itemsQuantity));
    dispatch(fetchAccrualsList());
  };

  const changeDateRangeHandler = (range: { from: Date; to: Date }) => {
    console.log(range);
    const fromDate = range.from.toISOString();
    const toDate = range.to.toISOString();
    dispatch(
      setDateFilter({
        fromDate: fromDate === toDate ? null : fromDate,
        toDate: fromDate === toDate ? null : toDate,
      }),
    );
    dispatch(fetchAccrualsList());
  };

  const setSearchFilterHandler = (value: string) => {
    dispatch(setSearch(value === '' ? null : value));
    dispatch(fetchAccrualsList());
  };

  const setSortHandler = (sortBy: { sortBy: keyof ICharge; sortDir: 'ASC' | 'DESC' | 'NONE' }) => {
    dispatch(
      setSort({
        sortBy: sortBy.sortDir === 'NONE' ? null : sortBy.sortBy,
        sortDir: sortBy.sortDir === 'NONE' ? null : sortBy.sortDir,
      }),
    );
    dispatch(fetchAccrualsList());
  };

  const getAccrualsList = () => {
    dispatch(fetchAccrualsList());

    return setTimeout(() => {
      getAccrualsList();
    }, GET_WORKERS_TIMEOUT_MS);
  };

  useEffect(() => {
    const timeout = getAccrualsList();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const download = async () => {
    const data = await dispatch(fetchExportAccruals()).unwrap();
    downloadCsvFile(data.exportData, 'Accruals');
  };

  return (
    <Layout>
      <UserDataLayoutContainer pageName="profitPage">
        <StyledContent>
          <ServicePageHero
            title={t('serviceHero.changeWalletButton')}
            onClickButton={changeWalletButton}
          ></ServicePageHero>
          <AccuralSchedule />
          <ChargesListContainer>
            <ChargesListControls>
              <DownloadDataButton onClick={download} />
              <CustomCalendar onChange={changeDateRangeHandler} />
              <Search onChange={setSearchFilterHandler} />
            </ChargesListControls>
            <ChargeList onSort={setSortHandler} />
            {pagesQuantity > 0 && (
              <div className="pagination-container">
                <Paginator
                  currentPage={currentPage}
                  pageQuantity={pagesQuantity}
                  onChange={changeCurrentPageHandler}
                />
                <DisplayItemsPerPageSelect
                  onChange={changeItemsPerPageQuantityHandler}
                  currentItemsPerPageQuantity={itemsPerPage}
                />
              </div>
            )}
          </ChargesListContainer>

          <Footer smaller />
        </StyledContent>
      </UserDataLayoutContainer>
    </Layout>
  );
};
