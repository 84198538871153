import { StyledBorderedWrapper } from 'components/shared/BorderedWrapper/styles';
import styled from 'styled-components';
export const StyledAccuralSchedule = styled.section`
  margin-bottom: 1.4rem;

  ${StyledBorderedWrapper} {
    @media screen and (min-width: 768px) {
      padding: 2rem 2.4rem;
    }
  }

  .accural-schedule-heading {
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */
    margin-bottom: 0.2rem;

    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }
  }

  .accural-schedule-credits-fixed {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 15px */
    margin-bottom: 2.4rem;
    @media screen and (min-width: 768px) {
      font-size: 1.4rem;
    }
  }

  .accural-schedule-diagram {
    min-height: 20rem;
    margin-bottom: 2.5rem;
    @media screen and (min-width: 768px) {
      margin-bottom: 3rem;
    }
    @media screen and (min-width: 1024px) {
      margin-bottom: 4rem;
    }
    @media screen and (min-width: 1440px) {
      margin-bottom: 4.5rem;
    }
  }
  .accural-schedule-total-accured {
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 18px */
    @media screen and (min-width: 768px) {
      font-size: 1.8rem;
    }

    &__title {
      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text2 : props.theme.text1)};
      margin-right: 0.6rem;
    }

    &__value {
      font-size: 1.4rem;
      font-weight: 500;
      @media screen and (min-width: 768px) {
        font-size: 1.8rem;
      }
    }
  }
`;
