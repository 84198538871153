import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchServerDateTime } from 'store/thunks/adminPaymentThunks';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IAdminPaymentState {
  status: ESliceDataFetchingStatus;
  errorMessage: string | null;
  serverDateTime: string | null;
}

const initialState: IAdminPaymentState = {
  status: ESliceDataFetchingStatus.initial,
  errorMessage: null,
  serverDateTime: null,
};

export const adminPaymentsSlice = createSlice({
  name: 'adminPayments',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchServerDateTime.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.serverDateTime = payload.time;
      })
      .addCase(fetchServerDateTime.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchServerDateTime.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch server date error';
      }),
});

export const selectServerDateTime = (state: RootState) => state.adminPayments;

export const adminPaymentsReducer = adminPaymentsSlice.reducer;
