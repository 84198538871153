import { EApiResponseStatus } from 'types/api';

export const apiClient = {
  get: async (url: string, headers?: Record<string, string>) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (response.status === 401) {
        return { status: EApiResponseStatus.FAILURE, error: 'unauthorized' };
      }

      if (!response.ok)
        throw Error(`faild to fetch with status ${response.status}, ${response.statusText}`);

      const res = await response.text();
      try {
        const jsonData = JSON.parse(res);
        return { status: 'success', data: jsonData };
      } catch (err) {
        return { status: 'success', data: res };
      }
    } catch (error) {
      console.error('api client post error', error);
      return { status: EApiResponseStatus.FAILURE, error: 'apiClientError' };
    }
  },
  post: async (
    url: string,
    data: unknown,
    options: { queryParams?: Record<string, string>; headers?: Record<string, string> },
  ) => {
    try {
      const { queryParams, headers } = options;
      const query = new URLSearchParams(queryParams).toString();
      const apiUrl = `${url}?${query}`;

      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...headers },
      });

      const res = await response.text();

      if (response.status >= 500) {
        return { status: EApiResponseStatus.FAILURE, error: res };
      }

      if (response.status === 401) {
        return { status: EApiResponseStatus.FAILURE, error: 'unauthorized' };
      }

      if (response.status === 412) {
        return { status: EApiResponseStatus.FAILURE, error: 'wrongUserOrPassword' };
      }
      if (response.status === 415) {
        return { status: EApiResponseStatus.FAILURE, error: 'unprocessibleData' };
      }

      try {
        const jsonData = JSON.parse(res);
        return { status: EApiResponseStatus.SUCCESS, data: jsonData };
      } catch (err) {
        return { status: EApiResponseStatus.SUCCESS, data: res };
      }
    } catch (error) {
      console.error('api client post error', error);
      return { status: EApiResponseStatus.FAILURE, error: 'apiClientError' };
    }
  },
};
