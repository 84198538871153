import { ICharge, TSortChargeByHeaderTitle } from 'types';
import { v4 as uuid4 } from 'uuid';
import { StyledChargeList } from './styles';
import { ChargeListHeader } from '../ChargeListHeader';
import { useState } from 'react';
import { ChargeListElement } from '../ChargeListElement';
import { useSelector } from 'react-redux';
import { selectAccruals } from 'store/slices/userAccrualSlice';
import { getNextSortDir } from 'utils';
interface IChargeListProps {
  children?: React.ReactNode;
  onSort: (sortBy: { sortBy: keyof ICharge; sortDir: 'ASC' | 'DESC' | 'NONE' }) => void;
}
export const ChargeList = ({ onSort }: IChargeListProps) => {
  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortDir, setSortDir] = useState<'ASC' | 'DESC' | 'NONE'>('NONE');
  const sortHandler = (nextSortBy: TSortChargeByHeaderTitle) => {
    const nextSortDir = getNextSortDir(sortBy, nextSortBy, sortDir);
    onSort({ sortBy: nextSortBy, sortDir: nextSortDir });
    setSortBy(nextSortBy);
    setSortDir(nextSortDir);
  };

  const { accruals } = useSelector(selectAccruals);
  return (
    <StyledChargeList>
      <ChargeListHeader onSort={sortHandler} sortBy={sortBy} sortDir={sortDir} />
      {accruals.map((accrual) => (
        <ChargeListElement key={uuid4()} {...accrual} />
      ))}
    </StyledChargeList>
  );
};
