export const apiGateway = 'https://api.pool.rutbot.com';

export const apiRoutes = {
  apiSignInPath() {
    return `${apiGateway}/login`;
  },
  apiSignUpPath() {
    return `${apiGateway}/signup`;
  },
  apiFetchHashrate() {
    return `${apiGateway}/pool/hashrate`;
  },
  apiFetchUserPath() {
    return `${apiGateway}/user/info`;
  },
  apiFetchUserHashRate() {
    return `${apiGateway}/user/hashrate`;
  },
  apiFetchUserStat() {
    return `${apiGateway}/user/stat`;
  },
  apiAddWorker() {
    return `${apiGateway}/user/worker/add`;
  },
  apiDeleteWorker() {
    return `${apiGateway}/user/worker/delete`;
  },
  apiFetchWorkers() {
    return `${apiGateway}/user/workers`;
  },
  apiFetchWorkerHashrate(workerId: number) {
    return `${apiGateway}/user/worker/hashrate/${workerId}`;
  },
  apiFetchUserUrlsPath() {
    return `${apiGateway}/user/links`;
  },
  apiFetchWorkersGroups() {
    return `${apiGateway}/user/worker/groups`;
  },
  apiAddWorkersGroup() {
    return `${apiGateway}/user/worker/group/add`;
  },
  apiDeleteWorkersGroup() {
    return `${apiGateway}/user/worker/group/delete`;
  },
  apiAssignWorkerGroupToWorker() {
    return `${apiGateway}/user/worker/group/assign`;
  },
  apiUnassignWorkerGroupToWorker() {
    return `${apiGateway}/user/worker/group/unassign`;
  },
  apiRenameWorkersGroup() {
    return `${apiGateway}/user/worker/group/rename`;
  },
  apiFetchPayouts() {
    return `${apiGateway}/user/payments`;
  },
  apiExportPayouts() {
    return `${apiGateway}/user/payments/export`;
  },
  apiFetchAccruals() {
    return `${apiGateway}/user/accruals`;
  },
  apiExportAccruals() {
    return `${apiGateway}/user/accruals/export`;
  },
  apiFetchReferralStats() {
    return `${apiGateway}/user/referrals/stat`;
  },
  apiFetchReferralUsers() {
    return `${apiGateway}/user/referrals`;
  },
  apiFetchReferralRewards() {
    return `${apiGateway}/user/referrals/accruals`;
  },
  apiFetchReferralPayouts() {
    return `${apiGateway}/user/referrals/payments`;
  },
  apiFetchSettings() {
    return `${apiGateway}/user/settings`;
  },
  apiUserSettingsEmail() {
    return `${apiGateway}/user/settings/email`;
  },
  apiUserSettingsTelegram() {
    return `${apiGateway}/user/settings/telegram`;
  },
  apiUserSettingsPhone() {
    return `${apiGateway}/user/settings/phone`;
  },
  apiUserSettingsNewPassword() {
    return `${apiGateway}/user/settings/password`;
  },
  apiUserSettingsBTCAddress() {
    return `${apiGateway}/user/settings/btc/address`;
  },
  apiUserSettings2fa() {
    return `${apiGateway}/user/settings/2fa`;
  },
  apiUserNotificationSettings() {
    return `${apiGateway}/user/settings/notifications`;
  },
  apiUserLanguage() {
    return `${apiGateway}/user/settings/language`;
  },
  apiUserTheme() {
    return `${apiGateway}/user/settings/theme`;
  },
  apiDonateSetting() {
    return `${apiGateway}/user/settings/donate`;
  },
  apiTimezoneSettings() {
    return `${apiGateway}/user/settings/timezone`;
  },
};
