import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchCosts } from 'store/thunks/adminCostThunk';
import { ESliceDataFetchingStatus, TNullable } from 'types/common';

export interface IAdminCostState {
  status: ESliceDataFetchingStatus;
  errorMessage: string | null;
  serverDateTime: string | null;
  yesterdayCost: TNullable<number>;
  newbieCost: TNullable<number>;
  newbieAdjust: TNullable<number>;
}

const initialState: IAdminCostState = {
  status: ESliceDataFetchingStatus.initial,
  errorMessage: null,
  serverDateTime: null,
  yesterdayCost: null,
  newbieCost: null,
  newbieAdjust: null,
};

export const adminCostsSlice = createSlice({
  name: 'adminCosts',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchCosts.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchCosts.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch admin costs error';
      })
      .addCase(fetchCosts.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.yesterdayCost = Number(payload.yesterdayCost);
        state.newbieCost = Number(payload.newbieCost);
        state.newbieAdjust = Number(payload.newbieAdjust);
      }),
});

export const selectCosts = (state: RootState) => state.adminCosts;

export const adminCostsReducer = adminCostsSlice.reducer;
