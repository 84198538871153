import { StyledButton } from 'components/shared/Button/styles';
import { StyledWorkerGroupsDropdown } from 'routes/workers/components/WorkerGroupsDropdown/styles';
import styled from 'styled-components';

export const StyledActionsDialog = styled.div`
  ${StyledButton} {
    font-size: 1.6rem;
  }

  ${StyledWorkerGroupsDropdown} {
    max-height: 15rem;
    overflow: auto;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay {
    width: 100%;
    height: 100%;
  }

  .actions-dialog {
    max-width: 42rem;
    overflow: hidden;
    display: block;
    margin: auto;
    background-color: ${(props) => props.theme.bg1};
    border: 1px solid ${(props) => props.theme.stroke2};
    border-radius: 10px;
    color: ${(props) => props.theme.text1};
    padding: 2rem 6rem 4rem;

    .action-success,
    .action-error {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      font-size: 1.8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      opacity: 0.95;
      background-color: ${(props) => props.theme.bg1};
    }

    .action-success {
      color: ${(props) => props.theme.button1};
    }

    .action-error {
      color: ${(props) => props.theme.errorRed};
    }

    .heading {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 150%;
      text-align: center;
      padding-bottom: 1rem;
    }

    .subtitle {
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 150%;
      text-align: center;
    }

    .groups-selector {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }

    .controls {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
      padding-top: 1.5rem;
    }
  }
`;
export const StyledWorkersListElement = styled.div`
  z-index: 1;
  display: flex;

  margin-bottom: 0.2rem;

  font-size: 1.2rem;
  font-weight: 600;
  line-height: 150%;
  text-align: center;

  &:last-child {
    margin-bottom: 1rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.4rem;
  }

  &:last-child {
    .worker-list-element-actions-dropdown {
      bottom: -1rem;
    }
  }

  .worker-list-element-col {
    position: relative;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 8rem;

    padding: 1rem;

    border-right: 1px solid
      ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

    background-color: ${(props) => (props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg1)};

    @media screen and (min-width: 768px) {
      width: 15.5rem;
    }

    &.select-element {
      position: relative;

      justify-content: flex-start;
      align-items: center;

      width: auto;

      padding: 0;
      padding-right: 1rem;

      border-right: none;

      color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.accent4)};

      background: none;

      .select-element-input {
        position: absolute;
        visibility: hidden;

        width: 0;
        height: 0;

        border: none;
        &:checked ~ {
          .select-element-label {
            color: ${(props) =>
              props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};

            > * {
              *[fill] {
                fill: currentColor;
              }
            }
          }
        }
      }
      .select-element-label {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 1.4rem;
        height: 1.4rem;

        color: inherit;

        cursor: pointer;

        @media screen and (min-width: 1440px) {
          width: 1.6rem;
          height: 1.6rem;
        }
        > * {
          width: 100%;
          height: 100%;
          *[stroke] {
            stroke: currentColor;
          }
        }
      }
      &:hover {
        .select-element-label {
          color: ${(props) =>
            props.theme.id === 'dark' ? props.theme.accent1 : props.theme.text2};
        }
      }
    }

    &.reject {
      width: 11rem;
    }

    &.status {
      @media screen and (min-width: 768px) {
        padding-right: 3rem;
      }

      .status-text {
        flex-grow: 1;
      }
      .status-icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        width: 1.5rem;
        height: 1.5rem;

        margin-left: auto;

        > * {
          width: 100%;
          height: 100%;
        }
      }
    }
    &.actions {
      width: 10rem;
    }
    &.last-activity {
      flex-grow: 1;
      width: 21rem;
    }
  }

  .worker-list-element-actions-button {
    width: 100%;
    height: 100%;

    &:hover {
      scale: 1.2;
    }
  }

  .worker-list-element-actions-dropdown {
    position: absolute;
    right: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    border: 1px solid ${(props) => props.theme.stroke2};
    border-radius: 10px;
    padding: 1.5rem;

    background-color: ${(props) => props.theme.bg1};

    box-shadow: 0px 30px 52px -8px #00000066;

    &-button {
      text-align: start;
      width: 100%;
      color: ${(props) => props.theme.text1};
      white-space: nowrap;
      font-size: 1.8rem;
      font-weight: 500;
      line-height: 150%;

      &:hover {
        color: ${(props) => props.theme.accent1};
      }
      &:disabled,
      &:disabled:hover,
      &:disabled:active {
        color: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
        background-color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.bg2 : props.theme.bg3};
        box-shadow: none;

        .button_icon {
          > * {
            fill: ${(props) => (props.theme.id === 'dark' ? props.theme.text3 : props.theme.text1)};
          }
        }
      }
    }
  }

  &:last-child {
    border-right: none;
  }

  &:hover,
  &.selected {
    .worker-list-element-col {
      &:not(.select-element) {
        background-color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.bg6 : props.theme.accent4};
      }
      &.actions {
        color: ${(props) =>
          props.theme.id === 'dark' ? props.theme.accent1 : props.theme.accent5};
      }
    }
  }
`;
