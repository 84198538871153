import { useTranslation } from 'react-i18next';
import { BorderedWrapper, Modal } from 'components';
import { ReactComponent as CloseButton } from 'assets/icons/close-cross.svg';
import { ReactComponent as WrongIcon } from 'assets/icons/wrong.svg';
import { StyledForm, StyledFormHeader, StyledFromModal } from './styles';
import { EFromStates } from 'types';
interface IFormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  header: React.ReactNode;
  formState?: EFromStates;
  setFormState?: (formState: EFromStates) => void;
  errorMessage?: string;
  onFormBack?: (clearInputs: boolean) => void;
}

export const Form = (props: IFormProps) => {
  const { t } = useTranslation();

  const { header, children, formState, setFormState, errorMessage, onSubmit } = props;

  const closeModalHandler: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (setFormState) setFormState(EFromStates.initial);
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <Modal show={formState && formState !== EFromStates.initial}>
        <StyledFromModal>
          <div className="form-modal-overlay" />
          <div className="form-modal-content">
            {formState === EFromStates.submitting && (
              <div className="form-modal-status">{t(`forms.formStatus.${formState}`)}</div>
            )}
            {formState === EFromStates.failure && (
              <dialog open className="form-modal-dialog">
                <BorderedWrapper>
                  <button
                    className="form-modal-close-button"
                    type="button"
                    onClick={closeModalHandler}
                  >
                    <CloseButton className="close-icon" />
                  </button>
                  <div className="form-modal-message">
                    <div className="form-modal-status error">
                      {t(`forms.formStatus.${formState}`)}
                    </div>
                    <div className="form-modal-error-message">{errorMessage}</div>
                  </div>
                  <WrongIcon />
                </BorderedWrapper>
              </dialog>
            )}
          </div>
        </StyledFromModal>
      </Modal>
      <StyledFormHeader>{header}</StyledFormHeader>
      {children}
    </StyledForm>
  );
};
